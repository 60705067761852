import type { NotificationElement } from '@vaadin/vaadin-notification';
import type { FxA11y } from './fx-a11y';
import { FxRenderer } from '@finantix/core';

export class FxA11yRenderer extends FxRenderer {
    private isOpened = false;
    public constructor() {
        super();
    }

    private overwritePopupCardStyles(): void {
        const style = getComputedStyle(document.documentElement);
        const marginLeft = style.getPropertyValue('--fx-spacing-4a');
        const marginTop = style.getPropertyValue('--fx-spacing-3a');
        const domModule = document.createElement('dom-module');
        domModule.innerHTML = `
            <template>
                <style>
                    :host {
                        margin: 0;
                    }
                    @media (min-width: 421px) {
                        :host {
                            width: fit-content;
                        }
                    }
                    :host(:last-child) {
                        margin-bottom: 64px;
                    }
                    [part="overlay"] {
                        background: none;
                        box-shadow: none;
                    }
                    [part="content"] {
                        padding: 0;
                        margin-top: ${marginTop};
                        margin-left: ${marginLeft};
                    }
                </style>
            </template>`;

        domModule.setAttribute('id', 'fx-vaadin-notification-card-style');
        domModule.setAttribute('theme-for', 'vaadin-notification-card');

        document.body.appendChild(domModule);
    }

    private overwritePopupContainerStyles(): void {
        const domModuleContainer = document.createElement('dom-module');
        domModuleContainer.innerHTML = `
            <template>
                <style>
                    [region-group="bottom"] > [region="bottom-start"] {
                        flex: none;
                    }
                </style>
            </template>`;

        domModuleContainer.setAttribute('id', 'fx-vaadin-notification-container-style');
        domModuleContainer.setAttribute('theme-for', 'vaadin-notification-container');

        document.body.appendChild(domModuleContainer);
    }

    public render(context: string, container: FxA11y): void {
        this.overwritePopupContainerStyles();
        this.overwritePopupCardStyles();

        if (container.currentPhase === 'FIRST_PAINTING') {
            const a11yButton: HTMLElement = document.createElement('button');
            a11yButton.className = 'fx-btn fx-btn-info action-link';

            const icon: HTMLElement = document.createElement('icon');
            icon.className = 'mdi mdi-human';
            a11yButton.appendChild(icon);

            document.addEventListener('keydown', container.onKeyDown.bind(container));
            a11yButton.addEventListener('click', this.createPopUp.bind(this, container));
            container.appendChild(a11yButton);
        }
    }

    private createPopUp(container: FxA11y): void {
        if (!this.isOpened) {
            const notification: NotificationElement = document.createElement('vaadin-notification');
            notification.setAttribute('position', 'bottom-start');
            notification.opened = true;
            notification.setAttribute('duration', '0');
            document.body.appendChild(notification);
            this.isOpened = true;

            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const _self = this;
            const content: HTMLElement = document.createElement('div');
            notification.renderer = function (root): void {
                content.className = 'a11y-notification';
                _self.setPopupContent(content, container);
                root.appendChild(content);
            };
            this.createCloseButton(notification, content);
        }
    }

    private setPopupContent(popup: HTMLElement, container: FxA11y): void {
        const windowsLabel = container.translateContent('fx-a11y.popup.content.windows-label');
        const macLabel = container.translateContent('fx-a11y.popup.content.macos-label');
        const windowsModifierKeys = 'Alt + Shift';
        const macModifierKeys = 'Option + Shift';

        const windowsShortcutsDescription: string = this.setShortcutDescription(
            container,
            windowsLabel,
            windowsModifierKeys
        );
        const macShortcutsDescription: string = this.setShortcutDescription(
            container,
            macLabel,
            macModifierKeys
        );

        popup.innerHTML = `
            <div>
                ${windowsShortcutsDescription}<br>
                ${macShortcutsDescription}
            </div>
        `;
    }

    private setShortcutDescription(
        container: FxA11y,
        systemLabel: string,
        modifierKeys: string
    ): string {
        const shortcutHeader: string = container
            .translateContent('fx-a11y.popup.content.single-shortcut', {
                shortcut: '<b>' + modifierKeys + ' + H</b>',
                zone: '<b>Header</b>'
            })
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<')
            .replace(/&#x2F;/g, '/');

        const shortcutLeft: string = container
            .translateContent('fx-a11y.popup.content.single-shortcut', {
                shortcut: '<b>' + modifierKeys + ' + L</b>',
                zone: '<b>Left panel</b>'
            })
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<')
            .replace(/&#x2F;/g, '/');

        const shortcutContent: string = container
            .translateContent('fx-a11y.popup.content.single-shortcut', {
                shortcut: '<b>' + modifierKeys + ' + C</b>',
                zone: '<b>Content</b>'
            })
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<')
            .replace(/&#x2F;/g, '/');

        const shortcutRight: string = container
            .translateContent('fx-a11y.popup.content.single-shortcut', {
                shortcut: '<b>' + modifierKeys + ' + R</b>',
                zone: '<b>Right panel</b>'
            })
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<')
            .replace(/&#x2F;/g, '/');

        const shortcutsDescription = `
            <h3>${systemLabel}</h3><br>
            ${shortcutHeader}<br>
            ${shortcutLeft}<br>
            ${shortcutContent}<br>
            ${shortcutRight}<br>
        `;
        return shortcutsDescription;
    }

    private createCloseButton(notification: NotificationElement, root: HTMLElement): void {
        const closeButton = document.createElement('button');
        closeButton.className = 'btn-close fx-btn fx-btn-tool fx-btn-info';
        closeButton.addEventListener('click', () => {
            notification.close();
            this.isOpened = false;
        });

        const closeIcon = document.createElement('i');
        closeIcon.className = 'mdi mdi-close';
        closeIcon.setAttribute('aria-hidden', 'true');
        closeButton.appendChild(closeIcon);

        root.appendChild(closeButton);
    }
}
