import { install, ResizeObserver } from 'resize-observer';

import { FxElement, FxElements, FxProperties } from '@finantix/core';

import { FxSelectRenderer } from './fx-select.renderer';
import { parseLibraryValue } from './fx-select.utils';
import i18n from './locales/locale.en.json';

import type TomSelect from 'tom-select/dist/esm/tom-select';

import type { Instance } from '@popperjs/core';

if (!ResizeObserver) {
    install();
}

// TODO: MISSING THINGS
// - value initially not set does not work
// - setOptions is not working
// - Add events firing
// - Multi select with counter (eg. Selected items (3))
// - Multi select with text + ellipsis (eg. Item 1, Item 2, It...)
// - maxItems
// - Virtual scroll
// - unit tests
// CHECK: THINGS TO CHECK
// - Case with complex array of objects
// - All property work and are linked to the respective attribute:
//   - Change FxSelect attribute -> FxSelect & TomSelect property are up to date
//   - Change FxSelect property -> FxSelect attribute & TomSelect property are up to date
//   - Change TomSelect property -> FxSelect attribute & property are up to date

@FxElements.register({
    selector: 'fx-select',
    i18n,
    renderer: new FxSelectRenderer()
})
export class FxSelect extends FxElement {
    /** @internal */ public _select?: HTMLSelectElement;
    /** @internal */ public _placeholderHml?: HTMLElement;
    /** @internal */ public _popper?: Instance;
    /** @internal */ public _ts?: TomSelect;
    /** @internal */ public _resizeObserver?: ResizeObserver;

    /**
     * The current value of the select
     *
     * In case of a multi select it should be a coma separated string: `'1,2,3'`
     *
     * @type {string}
     * @memberof FxSelect
     */
    @FxProperties.register({
        onChanged: (
            element: FxSelect,
            property: string,
            oldValue: string,
            newValue: string
        ): void => {
            if (element._ts) {
                const currentValue = parseLibraryValue(element._ts.getValue());
                if (currentValue !== newValue) {
                    element._ts.setValue(element.multi ? newValue.split(',') : newValue);
                }
            } else {
                if (oldValue !== newValue && element.value !== newValue) {
                    element.value = newValue;
                }
            }
        }
    })
    public value?: string;

    @FxProperties.register()
    public valueField?: string;

    @FxProperties.register()
    public labelField?: string;

    @FxProperties.register()
    public searchField?: string;

    @FxProperties.register()
    public placeholder = 'fx-select.input.placeholder.label';

    @FxProperties.register()
    public placeholderSearch = 'fx-select.input.placeholder-search.label';

    @FxProperties.register()
    public placeholderNoResult = 'fx-select.input.placeholder-no-result.label';

    @FxProperties.register({
        type: Boolean
    })
    public multi = false;

    @FxProperties.register()
    public appendTo?: string;

    @FxProperties.register({
        type: Number
    })
    public maxItems?: number;

    @FxProperties.register({
        type: Number
    })
    public maxOptions?: number;

    @FxProperties.register({
        type: Boolean
    })
    public searchable = false;

    @FxProperties.register({
        type: Boolean
    })
    public clearable = false;

    @FxProperties.register({
        type: Boolean
    })
    public disabled = false;

    @FxProperties.register({
        type: Boolean
    })
    public showCounter = false;

    @FxProperties.register()
    public counterLabel = 'fx-select.item-counter.label';

    @FxProperties.register({
        // eslint-disable-next-line jsdoc/require-jsdoc
        onChanged: (
            element: FxSelect,
            property: string,
            oldValue: unknown,
            newValue: unknown
        ): void => {
            const icon: HTMLElement = element.querySelector('.dropdown-arrow');
            const isClosed = element._ts && !element._ts.isOpen;
            if (icon && isClosed && typeof oldValue === 'string' && typeof newValue === 'string') {
                icon.classList.replace(oldValue, newValue);
            }
        }
    })
    public dropdownIconClose = 'mdi-chevron-down';

    @FxProperties.register({
        // eslint-disable-next-line jsdoc/require-jsdoc
        onChanged: (
            element: FxSelect,
            property: string,
            oldValue: unknown,
            newValue: unknown
        ): void => {
            const icon: HTMLElement = element.querySelector('.dropdown-arrow');
            const isOpen = element._ts && element._ts.isOpen;
            if (icon && isOpen && typeof oldValue === 'string' && typeof newValue === 'string') {
                icon.classList.replace(oldValue, newValue);
            }
        }
    })
    public dropdownIconOpen = 'mdi-chevron-up';

    /**
     * Creates an instance of FxSelect.
     *
     * @memberof FxSelect
     */
    public constructor() {
        super();

        this._resizeObserver = new ResizeObserver(() => {
            if (this._popper) {
                this._popper.update();
            }
        });
    }

    /**
     * Retrieve the TomSelect instance
     *
     * @deprecated Use any method/property directly from `fx-select` itself rather then accessing
     * some internal value
     * @readonly
     * @type {TomSelect}
     * @memberof FxSelect
     */
    public get ts(): TomSelect {
        return this._ts;
    }

    /**
     * Default method called when the Element is disconnected from the DOM
     *
     * @memberof FxSelect
     */
    public onDisconnected(): void {
        this.destroy();
        super.onDisconnected();
    }

    /**
     * Reset the current status of some internal properties
     *
     * @memberof FxSelect
     */
    public reset(): void {
        this._resizeObserver?.unobserve(this._ts.dropdown);
        this._popper?.destroy();
        this._ts?.destroy();

        this._popper = undefined;
        this._ts = undefined;
    }

    /**
     * Set the available options programmatically
     *
     * @template Option
     * @param {Option[]} options a list of options. This can be an array of string or objects, see
     * the properties `valueField`, `labelField` and `searchField` to bind them correctly
     * @memberof FxSelect
     */
    public setOptions<Option>(options: Option[]): void {
        if (this._ts) {
            this._ts.clear(true);
            this._ts.clearOptions();
            this._ts.addOption(options);
        }
    }
}
