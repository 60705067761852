import type { ColDef, GridOptions, ICellRendererFunc, ValueGetterParams } from 'ag-grid-community';
import {
    FxTableDatasource,
    DatasourceConfiguration,
    DatasourceType,
    DatasourceSort,
    DatasourceSortOrder
} from '../core';
import { TableCell, TableCellType, TableColumn, TableRow } from '../models';

export interface AgGridCellClassParams {
    value: any;
    colDef: ColDef;
    data: TableRow;
}

export const supportedAgGridOptions: { [key: string]: string[] } = {
    columnDefs: ['object'],
    rowData: ['object'],
    getRowClass: ['function'],
    rowClass: ['string', 'object'],
    postSort: ['function']
};

export const supportedAgGridColumnDefOptions: { [key: string]: string[] } = {
    hide: ['boolean'],
    width: ['number'],
    minWidth: ['number'],
    headerName: ['string'],
    field: ['string'],
    headerClass: ['string', 'object'],
    cellClass: ['string', 'object', 'function'],
    cellStyle: ['object', 'function'],
    cellRenderer: ['function'],
    valueGetter: ['function']
};

export class FxTableAgGridDatasource extends FxTableDatasource {
    private _data: TableRow[];

    public constructor(gridOptions: GridOptions, tableState?: { columns; sort }) {
        super(FxTableAgGridDatasource.prepareConfiguration(gridOptions, tableState, false));
        this._data = this._prepareData(gridOptions);
    }

    /**
     * Prepares DataSourceConfiguration basing on ag grid options and state
     * @param gridOptions
     * @param tableState
     */
    public static prepareConfiguration(
        gridOptions: GridOptions,
        tableState?: { columns; sort },
        enableValidation?: boolean
    ): DatasourceConfiguration {
        if (enableValidation) {
            this.validateGridOptions(gridOptions);
        }

        const columns: TableColumn[] = [];
        let sorting: DatasourceSort[] | undefined;
        const columnsByField = FxTableAgGridDatasource._getColumnsByField(gridOptions);

        if (tableState) {
            tableState.columns
                .filter((column) => {
                    return !column.hide;
                })
                .forEach((column) => {
                    if (columnsByField[column.colId]) {
                        const tableColumn: TableColumn = {
                            width: column.width,
                            title: columnsByField[column.colId].headerName,
                            property: column.colId,
                            layout: {}
                        };
                        if (columnsByField[column.colId].headerClass && tableColumn.layout) {
                            tableColumn.layout.cssClass = [
                                columnsByField[column.colId].headerClass as string
                            ];
                        }
                        columns.push(tableColumn);
                    }
                });

            sorting = FxTableAgGridDatasource._getSortingFromState(tableState);
        } else {
            if (gridOptions.columnDefs) {
                gridOptions.columnDefs
                    .filter((colDef: ColDef) => {
                        return !colDef.hide;
                    })
                    .forEach((colDef: ColDef) => {
                        const tableColumn: TableColumn = {
                            width: colDef.width || 0,
                            minWidth: colDef.minWidth,
                            title: colDef.headerName,
                            property: colDef.colId || colDef.field,
                            layout: {}
                        };
                        if (colDef.headerClass && tableColumn.layout) {
                            if (typeof colDef.headerClass === 'string') {
                                tableColumn.layout.cssClass = [colDef.headerClass];
                            } else {
                                tableColumn.layout.cssClass = colDef.headerClass;
                            }
                        }
                        columns.push(tableColumn);
                    });
            }
        }

        const config: DatasourceConfiguration = {
            type: DatasourceType.Static,
            columns,
            sort: sorting
        };

        return config;
    }

    private static validateGridOptions(gridOptions: GridOptions): void {
        for (const key in gridOptions) {
            if (!supportedAgGridOptions[key]) {
                console.error(`[AG-GRID.DATASOURCE] unsupported ag-grid options property: ${key}`);
            } else if (!supportedAgGridOptions[key].includes(typeof gridOptions[key])) {
                console.error(
                    `[AG-GRID.DATASOURCE] unsupported ag-grid option property type: ${key}: ${typeof gridOptions[
                        key
                    ]}`
                );
            }
        }

        if (gridOptions.columnDefs) {
            gridOptions.columnDefs.forEach((colDef: ColDef) => {
                for (const key in colDef) {
                    if (!supportedAgGridColumnDefOptions[key]) {
                        console.error(
                            `[AG-GRID.DATASOURCE] unsupported ag-grid column property: ${key} for column: ${colDef.field}`
                        );
                    } else if (!supportedAgGridColumnDefOptions[key].includes(typeof colDef[key])) {
                        console.error(
                            `[AG-GRID.DATASOURCE] unsupported ag-grid column property type: ${key}: ${typeof colDef[
                                key
                            ]}`
                        );
                    }
                }
            });
        }
    }

    /**
     * Prepares datasource sorting base on ag grid table state
     * @param tableState
     */
    private static _getSortingFromState(tableState: {
        columns;
        sort;
    }): DatasourceSort[] | undefined {
        const result: DatasourceSort[] = [];

        if (tableState.sort) {
            tableState.sort.forEach((sort: { colId: string; sort: string }) => {
                result.push({
                    property: sort.colId,
                    order: DatasourceSortOrder[sort.sort]
                });
            });
        }

        return result.length > 0 ? result : undefined;
    }

    private static _getColumnsByField(gridOptions: GridOptions): { [key: string]: ColDef } {
        const result = {};

        if (gridOptions.columnDefs) {
            gridOptions.columnDefs.forEach((colDef: ColDef) => {
                if (colDef.colId || colDef.field) {
                    result[colDef.colId || colDef.field] = colDef;
                }
            });
        }
        return result;
    }

    /**
     * Returns rows for displaying
     * @param params
     */
    public getData(params?: any): TableRow[] {
        return this._data;
    }

    /**
     * Prepares array of fx-table TableRows basing on rowData passed in ag grid options
     * @param gridOptions
     */
    private _prepareData(gridOptions: GridOptions): TableRow[] {
        const rootNode: TableRow = { cells: [], isGrouped: true, children: [] };

        if (gridOptions.rowData) {
            let rowData = gridOptions.rowData;
            if (this.configuration.sort) {
                rowData = this._sortData(rowData);
                rowData = this._postSort(gridOptions, rowData);
            }
            const prepareNestedData = (rows: any[], parent: TableRow): void => {
                rows.forEach((row: any, rowIndex: number) => {
                    const isGrouped = row.children && row.children.length > 0;
                    const isTotal = row.total || row.isSummaryRow;
                    if (row.children) {
                        const cellsParent: TableCell[] = this.prepareCells(row, gridOptions);
                        const newTableRow: TableRow = {
                            isGrouped,
                            isTotal,
                            cells: cellsParent,
                            children: []
                        };
                        newTableRow.rawData = row;

                        this.updateRowClass(newTableRow, rowIndex, gridOptions);
                        parent.children.push(newTableRow);
                        prepareNestedData(row.children, newTableRow);
                    } else {
                        const cells = this.prepareCells(row, gridOptions);
                        const newTableRow: TableRow = { cells, isGrouped, isTotal };
                        newTableRow.rawData = row;
                        this.updateRowClass(newTableRow, rowIndex, gridOptions);
                        parent.children.push(newTableRow);
                    }
                });
            };
            prepareNestedData(rowData, rootNode);
        }
        return rootNode.children ? rootNode.children : [];
    }

    /**
     * Updates table tow css class
     * @param row table row
     * @param rowIndex row index
     * @param gridOptions ag-grid options
     */
    private updateRowClass(row: TableRow, rowIndex: number, gridOptions: GridOptions): void {
        const classesList: string[] = [];

        if (gridOptions.rowClass) {
            let classes = gridOptions.rowClass;
            if (typeof classes === 'string') {
                classes = classes.split(' ');
            }
            if (classes) {
                classesList.push(...classes);
            }
        }
        if (gridOptions.getRowClass) {
            let classes: string | string[] = gridOptions.getRowClass({
                node: {
                    rowIndex
                },
                data: row.rawData
            });
            if (typeof classes === 'string') {
                classes = classes.split(' ');
            }
            if (classes) {
                classesList.push(...classes);
            }
        }
        if (classesList.length > 0) {
            row.rowCssClass = classesList;
        }
    }

    /**
     * Prepares cells for given table row
     * @param row table row
     * @param gridOptions ag-grid options
     * @returns list of cells
     */
    private prepareCells(row: TableRow, gridOptions: GridOptions): TableCell[] {
        const cells: TableCell[] = [];
        const columnsByField = FxTableAgGridDatasource._getColumnsByField(gridOptions);
        this.configuration.columns.forEach((column: TableColumn) => {
            const cell: TableCell = {
                type: TableCellType.Text,
                content: '',
                layout: {}
            };
            if (column.property) {
                const field = column.property;
                const colDef = columnsByField[field];
                cell.content = this.getCellContent(field, row, colDef);
            }
            if (column.property && cell.layout) {
                const colDef = columnsByField[column.property];
                if (colDef.cellClass) {
                    if (typeof colDef.cellClass === 'function') {
                        cell.layout.cssClass = (tableCell: TableCell): string | string[] => {
                            const params: AgGridCellClassParams = {
                                value: tableCell.content,
                                colDef,
                                data: row
                            };

                            return (
                                colDef.cellClass as (cellClassParams: any) => string | string[]
                            )(params);
                        };
                    } else {
                        cell.layout.cssClass = colDef.cellClass;
                    }
                }
                if (colDef.cellStyle) {
                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                    cell.layout.cellStyle = (tableCell: TableCell): { [key: string]: string } => {
                        if (colDef.cellStyle) {
                            if (typeof colDef.cellStyle === 'function') {
                                const params: AgGridCellClassParams = {
                                    value: tableCell.content,
                                    colDef,
                                    data: row
                                };

                                // eslint-disable-next-line @typescript-eslint/ban-types
                                return (colDef.cellStyle as Function)(params);
                            } else {
                                return colDef.cellStyle;
                            }
                        }
                    };
                }
                if (colDef.cellRenderer) {
                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                    cell.layout.cellRenderer = (tableCell: TableCell): string | HTMLElement => {
                        if (colDef.cellRenderer) {
                            if (typeof colDef.cellRenderer === 'function') {
                                const params = {
                                    value: tableCell.content,
                                    colDef,
                                    data: row
                                };
                                return (colDef.cellRenderer as ICellRendererFunc)(params);
                            } else {
                                return tableCell.content;
                            }
                        } else {
                            return '';
                        }
                    };
                }
            }
            cells.push(cell);
        });
        return cells;
    }

    /**
     * Handles ag grid post sort options
     * @param gridOptions Ag-Grid options
     * @param rows list of rows
     */
    private _postSort(gridOptions: GridOptions, rows: any[]): any[] {
        if (gridOptions.postSort) {
            const rowNodes: any[] = [];
            rows.forEach((row) => {
                rowNodes.push({
                    data: row
                });
            });
            gridOptions.postSort(rowNodes);
            rows = [];
            rowNodes.forEach((node) => {
                rows.push(node.data);
            });
        }
        return rows;
    }

    /**
     * Handles sorting of rows
     * @param rows list of rows
     */
    private _sortData(rows: any[]): any[] {
        return rows.sort((row1: any, row2: any) => {
            let result = 0;
            if (this.configuration.sort) {
                for (const sort of this.configuration.sort) {
                    if (row1[sort.property] === row2[sort.property]) {
                        result = 0;
                    } else {
                        if (sort.order === DatasourceSortOrder.Asc) {
                            result = row1[sort.property] > row2[sort.property] ? -1 : 1;
                        } else {
                            result = row1[sort.property] > row2[sort.property] ? 1 : -1;
                        }
                    }
                    if (result !== 0) {
                        return result;
                    }
                }
            }

            return result;
        });
    }

    /**
     * Returns cell content base on field name, table row and column
     * @param field field name
     * @param row table row
     * @param colDef table column
     * @returns content
     */
    private getCellContent(field: string, row: TableRow, colDef: ColDef): string {
        if (colDef.valueGetter) {
            const params: Partial<ValueGetterParams> = {
                colDef,
                data: row
            };
            const valueGetter = colDef.valueGetter as (
                params: Partial<ValueGetterParams>
            ) => string;
            return valueGetter(params);
        }

        return row[field];
    }
}
