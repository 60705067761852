import type { TableCell } from './table-cell.model';

export enum TableLayoutModifier {
    Align = 'align',
    Style = 'style',
    Format = 'formattings',
    FgColor = 'fgColor',
    BgColor = 'bgColor'
}

export interface TableLayout {
    align?: string;
    fgColor?: string;
    bgColor?: string;
    style?: string;
    formattings?: string[];

    cssClass?: string | string[] | ((cell?: TableCell) => string | string[]);
    cellRenderer?: (cell: TableCell) => string | HTMLElement;
    // eslint-disable-next-line @typescript-eslint/ban-types
    cellStyle?: { [key: string]: string } | ((cell: TableCell) => { [key: string]: string });
}
