import { FxElement, FxElements, FxRenderer } from '@finantix/core';

import * as i18n from '../locales/locale.en.json';
import {
    TableCell,
    TableCellType,
    TableColumn,
    TableComment,
    TableModel,
    TableRow,
    TableTitle
} from '../models';
import { FxTableUtils } from '../utils';
import { FxTableImageCellRenderer } from './image-table-cell.renderer';
import { FxTableRenderer } from './table-renderer';

import type { ToolbarGroup } from '../models/toolbar-group.model';
import type { FxTableCellRenderer } from './table-cell.renderer';
import type { FxTableDatasource } from './table.datasource';

export enum AddTableRowSide {
    Top = 'top',
    Bottom = 'bottom'
}

export enum AddTableColumnSide {
    Left = 'left',
    Right = 'right'
}

export enum TableAttributes {
    Editable = 'editable',
    FirstRowAsHeader = 'firstRowAsHeader',
    FirstColumnAsHeader = 'firstColumnAsHeader',
    LastRowAsFooter = 'lastRowAsFooter',
    DoNotRepeatHeader = 'doNotRepeatHeader',
    DoNotRepeatFooter = 'doNotRepeatFooter',
    AllowHorizontalScrolling = 'allowHorizontalScrolling',
    DoNotBreakSmallTables = 'doNotBreakSmallTables'
}

@FxElements.register({
    selector: 'fx-table',
    i18n
})
export class FxTable extends FxElement {
    public static get observedAttributes(): string[] {
        return [...super.observedAttributes, ...Object.values(TableAttributes)];
    }

    private _renderer: FxTableRenderer;

    private _tableTitle: TableTitle | undefined;
    private _tableComment: TableComment | undefined;
    private _tableModel: TableModel;
    private _inlineStyles: { [key: string]: string };

    private _toolbarItems: ToolbarGroup[];
    private _customCellRenderers: { [key: string]: FxTableCellRenderer } = {};
    private _actionsLocks: { [key: string]: boolean } = {};

    private _editedCell: { [key: string]: any } | null = null;

    private _resizedColumnIndex = 0;
    private _resizeInitialPosX = 0;
    private _resizingEventHandler: (event: MouseEvent) => void;
    private _resizingMouseUpHandler: (event: MouseEvent) => void;

    public onChange?: (tableMoel) => void;
    public onSelected?: (cells: TableCell[]) => void;
    public onAction?: (action: string, params?: { [key: string]: any }) => boolean;
    public onLayoutAdjust?: () => void;

    public constructor() {
        super();

        this._renderer = new FxTableRenderer(this);
        this._tableModel = FxTableUtils.getDefaultModel();
        this._toolbarItems = FxTableUtils.getDefaultToolbarItems(this);
        this._inlineStyles = FxTableUtils.getDefaultInlineStylesMap();
        this._registerTableCellRenderers();
    }

    public get inlineStyles(): { [key: string]: string } {
        return this._inlineStyles;
    }

    public get tableModel(): TableModel {
        return this._tableModel;
    }

    public get tableTitle(): TableTitle | undefined {
        return this._tableTitle;
    }

    public get tableComment(): TableComment | undefined {
        return this._tableComment;
    }

    public get renderer(): FxTableRenderer {
        return this._renderer;
    }

    public get editable(): boolean {
        return (
            (this.getAttribute(TableAttributes.Editable) || 'false').toLocaleLowerCase() === 'true'
        );
    }

    public get firstRowAsHeader(): boolean {
        return (
            (this.getAttribute(TableAttributes.FirstRowAsHeader) || 'false').toLocaleLowerCase() ===
            'true'
        );
    }

    public get doNotRepeatHeader(): boolean {
        return (
            (
                this.getAttribute(TableAttributes.DoNotRepeatHeader) || 'false'
            ).toLocaleLowerCase() === 'true'
        );
    }

    public get doNotBreakSmallTables(): boolean {
        return (
            (
                this.getAttribute(TableAttributes.DoNotBreakSmallTables) || 'true'
            ).toLocaleLowerCase() === 'true'
        );
    }

    public get doNotRepeatFooter(): boolean {
        return (
            (this.getAttribute(TableAttributes.DoNotRepeatFooter) || 'true').toLocaleLowerCase() ===
            'true'
        );
    }

    public get firstColumnAsHeader(): boolean {
        return (
            (
                this.getAttribute(TableAttributes.FirstColumnAsHeader) || 'false'
            ).toLocaleLowerCase() === 'true'
        );
    }

    public get lastRowAsFooter(): boolean {
        return (
            (this.getAttribute(TableAttributes.LastRowAsFooter) || 'false').toLocaleLowerCase() ===
            'true'
        );
    }

    public get allowHorizontalScrolling(): boolean {
        return (
            (
                this.getAttribute(TableAttributes.AllowHorizontalScrolling) || 'false'
            ).toLocaleLowerCase() === 'true'
        );
    }

    public get editedCell(): { [key: string]: any } | null {
        return this._editedCell;
    }

    public get toolbarItems(): ToolbarGroup[] {
        return this._toolbarItems;
    }

    public get html(): string {
        return this.renderer.table.outerHTML;
    }

    public set inlineStyles(stylesMap: { [key: string]: string }) {
        this._inlineStyles = stylesMap;
    }

    public set tableModel(model: TableModel) {
        this._tableModel = model;
    }

    public set datasource(datasource: FxTableDatasource) {
        this.tableModel = this._createTableModel(datasource);
    }

    public set tableTitle(title: TableTitle | undefined) {
        this._tableTitle = title;
    }

    public set tableComment(comment: TableComment | undefined) {
        this._tableComment = comment;
    }

    public set editable(value: boolean) {
        if (value) {
            this.setAttribute(TableAttributes.Editable, 'true');
        } else {
            this.removeAttribute(TableAttributes.Editable);
        }
    }

    public set firstRowAsHeader(value: boolean) {
        if (value) {
            this.setAttribute(TableAttributes.FirstRowAsHeader, 'true');
        } else {
            this.removeAttribute(TableAttributes.FirstRowAsHeader);
        }
    }

    public set doNotRepeatHeader(value: boolean) {
        if (value) {
            this.setAttribute(TableAttributes.DoNotRepeatHeader, 'true');
        } else {
            this.removeAttribute(TableAttributes.DoNotRepeatHeader);
        }
    }

    public set doNotBreakSmallTables(value: boolean) {
        if (value) {
            this.setAttribute(TableAttributes.DoNotBreakSmallTables, 'true');
        } else {
            this.setAttribute(TableAttributes.DoNotBreakSmallTables, 'false');
        }
    }

    public set doNotRepeatFooter(value: boolean) {
        if (value) {
            this.setAttribute(TableAttributes.DoNotRepeatFooter, 'true');
        } else {
            this.setAttribute(TableAttributes.DoNotRepeatFooter, 'false');
        }
    }

    public set firstColumnAsHeader(value: boolean) {
        if (value) {
            this.setAttribute(TableAttributes.FirstColumnAsHeader, 'true');
        } else {
            this.removeAttribute(TableAttributes.FirstColumnAsHeader);
        }
    }

    public set lastRowAsFooter(value: boolean) {
        if (value) {
            this.setAttribute(TableAttributes.LastRowAsFooter, 'true');
        } else {
            this.removeAttribute(TableAttributes.LastRowAsFooter);
        }
    }

    public set allowHorizontalScrolling(value: boolean) {
        if (value) {
            this.setAttribute(TableAttributes.AllowHorizontalScrolling, 'false');
        } else {
            this.removeAttribute(TableAttributes.AllowHorizontalScrolling);
        }
    }

    public set toolbarItems(items: ToolbarGroup[]) {
        this._toolbarItems = items;
    }

    public getRenderer(context: string): FxRenderer {
        return this._renderer;
    }

    public getActionsLocks(actionType: string): boolean {
        return this._actionsLocks[actionType];
    }

    public lockActions(actionType: string, locked: boolean): void {
        this._actionsLocks[actionType] = locked;
    }

    public getCustomCellRenderer(cellType: string): FxTableCellRenderer {
        return this._customCellRenderers[cellType];
    }

    public registerCustomCellRenderer(cellType: string, renderer: FxTableCellRenderer): void {
        this._customCellRenderers[cellType] = renderer;
    }

    public totalRows(): number {
        let result = 0;
        if (this.tableModel && this.tableModel.rows) {
            this.tableModel.rows.forEach((row: TableRow) => {
                result++;
                result += this.countChildRows(row.children);
            });
        }
        return result;
    }

    private countChildRows(tableRows?: TableRow[]): number {
        let result = 0;
        if (tableRows) {
            tableRows.forEach((row: TableRow) => {
                result++;
                if (row.children) {
                    result += this.countChildRows(row.children);
                }
            });
        }
        return result;
    }

    public setAttributes(attributes: { [key in TableAttributes]: boolean }): void {
        Object.keys(attributes).forEach((attributeKey) => {
            this.setAttribute(attributeKey, attributes[attributeKey].toString());
        });
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this._setupListeners();
    }

    private _setupListeners(): void {
        this._resizingEventHandler = (event: MouseEvent): void => {
            const tableContainer: HTMLElement | null = this.querySelector('.table');
            if (tableContainer) {
                const resizeIndicator: HTMLElement | null =
                    tableContainer.querySelector('.resize-indicator');

                if (resizeIndicator) {
                    resizeIndicator.style.left = `${event.clientX}px`;

                    const cell: HTMLTableCellElement = this._renderer.table.querySelector(
                        `[data-col-idx="${this._resizedColumnIndex}"]`
                    );
                    const widthDiff: number = event.clientX - this._resizeInitialPosX;
                    const newWidth: number = cell.clientWidth + widthDiff;

                    const column: TableColumn = this.tableModel.columns[this._resizedColumnIndex];
                    const minWidth: number = column.minWidth
                        ? column.minWidth
                        : FxTableUtils.MIN_COLUMN_WIDTH;
                    if (newWidth < minWidth) {
                        resizeIndicator.classList.add('error');
                    } else {
                        resizeIndicator.classList.remove('error');
                    }
                }
            }
        };

        this._resizingMouseUpHandler = (event: MouseEvent): void => {
            const tableContainer: HTMLElement | null = this.querySelector('.table-container');
            if (tableContainer && tableContainer.querySelector('.resize-indicator')) {
                this.endResizing(event.clientX);
                const indicator = tableContainer.querySelector('.resize-indicator');
                if (indicator) {
                    indicator.remove();
                }
            }
        };
    }

    public cancelCellEdit(): void {
        this._editedCell = null;
    }

    public performTableAction(action: string, params?: { [key: string]: any }): void {
        if (this.onAction && this.onAction(action, params)) {
            return;
        }

        switch (action) {
            case 'image':
                {
                    this._pickAFile();
                }
                break;
            case 'image-picked':
                {
                    if (params) {
                        FxTableUtils.setCellContent(
                            this,
                            TableCellType.Image,
                            params.content.content
                        );
                    }
                }
                break;
            case 'edit-cell':
                {
                    if (params) {
                        this._editedCell = params;
                    }
                }
                break;
            case 'end-edit':
                {
                    this._editedCell = null;
                    this.notifyChange();
                }
                break;
            case 'cells-selected':
                {
                    this._editedCell = null;
                    this.notifySelection();
                }
                break;
            case 'add-row-on-top':
                {
                    const indexes: number[] = [];
                    this.querySelectorAll('.row-selector-button.selected').forEach(
                        (el: Element) => {
                            indexes.push(Number(el.getAttribute('data-row-idx')));
                        }
                    );

                    FxTableUtils.addRow(
                        this,
                        AddTableRowSide.Top,
                        indexes.length > 0 ? indexes : undefined
                    );
                }
                break;
            case 'add-row-on-bottom':
                {
                    const indexes: number[] = [];
                    this.querySelectorAll('.row-selector-button.selected').forEach(
                        (el: Element) => {
                            indexes.push(Number(el.getAttribute('data-row-idx')));
                        }
                    );

                    FxTableUtils.addRow(
                        this,
                        AddTableRowSide.Bottom,
                        indexes.length > 0 ? indexes : undefined
                    );
                }
                break;
            case 'remove-row':
                {
                    const indexes: number[] = [];
                    this.querySelectorAll('.row-selector-button.selected').forEach(
                        (el: Element) => {
                            indexes.push(Number(el.getAttribute('data-row-idx')));
                        }
                    );
                    FxTableUtils.removeRow(this, indexes);
                }
                break;
            case 'add-column-on-left':
                {
                    const indexes: number[] = [];
                    this.querySelectorAll('.col-selector-button.selected').forEach(
                        (el: Element) => {
                            indexes.push(Number(el.getAttribute('data-col-idx')));
                        }
                    );
                    FxTableUtils.addColumn(
                        this,
                        AddTableColumnSide.Left,
                        indexes.length > 0 ? indexes : undefined
                    );
                }
                break;
            case 'add-column-on-right':
                {
                    const indexes: number[] = [];
                    this.querySelectorAll('.col-selector-button.selected').forEach(
                        (el: Element) => {
                            indexes.push(Number(el.getAttribute('data-col-idx')));
                        }
                    );

                    FxTableUtils.addColumn(
                        this,
                        AddTableColumnSide.Right,
                        indexes.length > 0 ? indexes : undefined
                    );
                }
                break;
            case 'remove-column':
                {
                    const indexes: number[] = [];
                    this.querySelectorAll('.col-selector-button.selected').forEach(
                        (el: Element) => {
                            indexes.push(Number(el.getAttribute('data-col-idx')));
                        }
                    );
                    FxTableUtils.removeColumn(this, indexes);
                }
                break;
            case 'bold':
            case 'italic':
            case 'underline':
                {
                    FxTableUtils.toggleCellFormatting(this, action);
                }
                break;
            case 'left':
            case 'right':
            case 'center':
            case 'justify':
                {
                    FxTableUtils.setCellAlignment(this, action);
                }
                break;
            case 'merge':
                {
                    FxTableUtils.mergeCells(this);
                }
                break;
            case 'split':
                {
                    FxTableUtils.splitCells(this);
                }
                break;
            case 'fg-color':
                {
                    if (params) {
                        FxTableUtils.setCellFgColor(this, params.color);
                    }
                }
                break;
            case 'bg-color':
                {
                    if (params) {
                        FxTableUtils.setCellBgColor(this, params.color);
                    }
                }
                break;
            default: {
                throw new Error('Unknown action: ' + action);
            }
        }
    }

    public startResizing(colIndex: number, posX: number): void {
        this._resizedColumnIndex = colIndex;
        this._resizeInitialPosX = posX;

        window.addEventListener('mousemove', this._resizingEventHandler);
        window.addEventListener('mouseup', this._resizingMouseUpHandler);
    }

    public endResizing(posX: number): void {
        window.removeEventListener('mousemove', this._resizingEventHandler);
        window.removeEventListener('mouseup', this._resizingMouseUpHandler);

        this.tableModel.columns.forEach((column: TableColumn, index: number) => {
            const cell = this._renderer.table.querySelector(
                `[data-col-idx="${index}"]`
            ) as HTMLTableCellElement;
            column.width = cell.clientWidth;
        });

        const widthDiff = posX - this._resizeInitialPosX;
        let newWidth = this.tableModel.columns[this._resizedColumnIndex].width + widthDiff;
        if (newWidth < FxTableUtils.MIN_COLUMN_WIDTH) {
            return;
        }
        this.tableModel.columns[this._resizedColumnIndex].width = newWidth;

        if (!this.allowHorizontalScrolling) {
            if (widthDiff > 0) {
                if (this.tableModel.columns.length - 1 > this._resizedColumnIndex) {
                    newWidth =
                        this.tableModel.columns[this._resizedColumnIndex + 1].width -
                        Math.abs(widthDiff);
                    if (newWidth < FxTableUtils.MIN_COLUMN_WIDTH) {
                        newWidth = FxTableUtils.MIN_COLUMN_WIDTH;
                    }
                    this.tableModel.columns[this._resizedColumnIndex + 1].width = newWidth;
                }
            } else {
                if (this.tableModel.columns.length - 1 > this._resizedColumnIndex) {
                    newWidth =
                        this.tableModel.columns[this._resizedColumnIndex + 1].width +
                        Math.abs(widthDiff);
                    this.tableModel.columns[this._resizedColumnIndex + 1].width = newWidth;
                }
            }
        }

        FxTableUtils.updateColumnsSize(this);
        this.notifyChange();
    }

    public notifyChange(): void {
        if (this.onChange) {
            this.onChange(this.tableModel);
        }
    }

    public notifySelection(): void {
        if (this.onSelected) {
            this.onSelected(FxTableUtils.getSelectedCells(this));
        }
    }

    public notifyAction(action: string, params?: { [key: string]: any }): void {
        if (this.onAction) {
            this.onAction(action, params);
        }
    }

    private _registerTableCellRenderers(): void {
        this._customCellRenderers[TableCellType.Image] = new FxTableImageCellRenderer();
    }

    private _pickAFile(): void {
        const filePicker = this.querySelector('.file-picker') as HTMLInputElement;
        if (filePicker) {
            filePicker.click();
        }
    }

    private _createTableModel(datasource: FxTableDatasource): TableModel {
        let rows: TableRow[] = [];
        const cells: TableCell[] = [];

        datasource.configuration.columns.forEach((column: TableColumn) => {
            cells.push({
                type: TableCellType.Text,
                content: column.title,
                layout: column.layout
            });
        });
        rows.push({
            cells
        });
        rows = rows.concat(datasource.getData());

        const model = {
            columns: datasource.configuration.columns,
            rows
        };

        return model;
    }
}
