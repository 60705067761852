import { fxWebComponentEventFactory } from '@finantix/core';
import type { FxDropdown } from '../fx-dropdown';
import type { FxMultiListItem } from './fx-multi-list-item';

export class FxMultiListItemMenuOpenEvent extends fxWebComponentEventFactory<{
    item: FxMultiListItem,
    menu: FxDropdown
}>(
    'fx-multi-list-item:menu-open',
    false
) { }

export class FxMultiListItemMenuCloseEvent extends fxWebComponentEventFactory<{
    item: FxMultiListItem,
    menu: FxDropdown
}>(
    'fx-multi-list-item:menu-close',
    false
) { }

export class FxMultiListItemRemoveEvent extends fxWebComponentEventFactory<{
    item: FxMultiListItem
}>(
    'fx-multi-list:item-remove',
    true
) { }

export class FxMultiListItemRemovedEvent extends fxWebComponentEventFactory<{
    item: FxMultiListItem
}>(
    'fx-multi-list:item-removed'
) { }

export class FxMultiListItemMovedEvent extends fxWebComponentEventFactory<{
    item: FxMultiListItem,
    newIndex: number,
    keysSorted?: string[]
}>(
    'fx-multi-list:item-moved'
) { }
