import { FxElement, FxElements } from '@finantix/core';
import { FxDropdownRenderer } from './fx-dropdown.renderer';
import { FxDropdownOpenEvent, FxDropdownCloseEvent } from './fx-dropdown.events';
import * as i18n from './locales/locale.en.json';

/**
 *
 */
@FxElements.register({
    selector: 'fx-dropdown',
    i18n,
    renderer: new FxDropdownRenderer()
})
export class FxDropdown extends FxElement {
    // Internal HTML references
    private _trigger: HTMLElement | null;
    private _variableHeight: boolean;
    private _classes: string[];
    private _appendTo: Element | null;
    private _triggerElementList: NodeListOf<HTMLElement> | null;

    public constructor() {
        super();
    }

    public static get observedAttributes(): string[] {
        return [...super.observedAttributes, 'trigger'];
    }

    public get variableHeight(): boolean {
        return this._variableHeight;
    }

    public get classes(): string[] {
        return this._classes;
    }

    public get triggerElement(): HTMLElement | null {
        let trigger: HTMLElement | null;
        if (this._trigger) {
            trigger = this._trigger;
        } else {
            const targetSelector: string | null = this.getAttribute('target');
            trigger = targetSelector ? document.querySelector(targetSelector) : null;
        }
        return trigger;
    }

    public get appendTo(): 'parent' | Element | ((ref: Element) => Element) | null {
        return this._appendTo;
    }

    public set triggerElement(element: HTMLElement | null) {
        this._trigger = element;
    }

    public onAttributeChanged(attr: any, oldVal: any, newVal: any): void {
        if (attr === 'trigger' && oldVal !== newVal) {
            this.triggerElement = document.querySelector(newVal);
        }

        super.onAttributeChanged(attr, oldVal, newVal);
    }

    public onOpen(callback: (event: FxDropdownOpenEvent) => void): void {
        this.addEventListener(FxDropdownOpenEvent.eventName, callback);
    }

    public onClose(callback: (event: FxDropdownCloseEvent) => void): void {
        this.addEventListener(FxDropdownCloseEvent.eventName, callback);
    }

    private copyTippyAttributesToTriggerElement(
        triggerElement: HTMLElement | null
    ): HTMLElement | null {
        if (triggerElement) {
            for (let i = 0; i < this.attributes.length; i++) {
                const tippyAttrPrefix = 'data-tippy-';
                const attr: Attr = this.attributes[i];
                if (attr.name.startsWith(tippyAttrPrefix)) {
                    triggerElement.setAttributeNode(attr.cloneNode(true) as Attr);
                }
            }
        }
        return triggerElement;
    }

    public onConnected(): void {
        this._variableHeight = this.getAttributeBool('variable-height', false);
        this._classes = this.getAttributeString('theme-class', '').split(' ');
        const triggerSelector: string | null = this.getAttribute('trigger');
        if (triggerSelector) {
            this._triggerElementList = document.querySelectorAll<HTMLElement>(triggerSelector);
            this._triggerElementList.forEach((element) => {
                if (element.closest('[hidden="true"]') == null) {
                    this.triggerElement = element;
                }
            });
        }
        this.copyTippyAttributesToTriggerElement(this.triggerElement);
        const appendToSelector: string | null = this.getAttribute('appendTo');
        if (appendToSelector) {
            this._appendTo = document.querySelector(appendToSelector);
        }
        super.onConnected();
    }
}
