interface NodeToHide {
    element: HTMLElement;
    initialDisplayValue: string;
}

export class FxTogglePageHeader extends HTMLElement {
    private hiddenContent: boolean;
    private created: boolean;
    private contentToHide: Array<NodeToHide> = [];
    private icon: HTMLElement;

    static get observedAttributes() {
        return ['selector'];
    }

    constructor() {
        super();
        this.created = false;
        this.hiddenContent = false;
    }

    connectedCallback() {
        this.createTogglePageHeader();
    }

    createTogglePageHeader() {
        if (this.created === false) {
            this.createButton();
            this.created = true;
        }
    }

    createButton() {
        let button = document.createElement('button');
        button.className = "fx-btn fx-btn-default";
        button.addEventListener('click', this.onToggleClick.bind(this));
        button.style.borderRadius = '50%';
        button.style.minWidth = '40px';
        button.style.height = '40px';
        button.style.width = '40px';
        button.style.padding = '0px';
        this.createIcon(button);
        this.appendChild(button);
    }

    createIcon(button: HTMLElement) {
        this.icon = document.createElement('i');
        this.icon.className = "mdi mdi-chevron-up";
        button.appendChild(this.icon);
    }

    onToggleClick() {
        if (this.hiddenContent === false) {
            this.hideContent();
        } else {
            this.showContent();
        }
    }

    hideContent() {
        const containerSelector = this._getContainerSelector();
        let page = this.closest(containerSelector) as HTMLElement;
        let selectorValue = this.getAttribute('selector') as string;
        let nodes = page.querySelectorAll(selectorValue);

        nodes.forEach(element => {
            let node = element as HTMLElement;
            this.contentToHide.push({ element: node,initialDisplayValue: node.style.display });
            node.style.display = 'none';
        });
        this.hiddenContent = true;

        if (this.icon) {
            this.icon.className = 'mdi mdi-chevron-down';
        }
    }

    showContent() {
        this.contentToHide.forEach(element => {
            let node = element.element;
            let display = element.initialDisplayValue;
            node.style.display = display;
        });
        this.hiddenContent = false;

        if (this.icon) {
            this.icon.className = 'mdi mdi-chevron-up';
        }
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (this.created && name === 'selector') {
            console.warn(`[fx-toggle-page-header] The toggle was attached to "${oldValue}" and now is attached to "${newValue}"`);
        }
    }

    _getContainerSelector(): string {
        const container: string | null = this.getAttribute('container');
        return container ? container as string : this._getDefaultContainer();
    }

    _getDefaultContainer(): string {
        return '.fx-page';
    }
}

customElements.define('fx-toggle-page-header', FxTogglePageHeader);
