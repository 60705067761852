import { FxRenderer } from '@finantix/core';
import type { FxMailList } from './fx-mail-list';
import '../../fx-spinner/fx-spinner';
import moment from 'moment';
import type { FxEmailPreview } from '../shared/models/fx-mail.model';

/**
 * FxMailList renderer class
 *
 * @export
 * @class FxMailListRenderer
 * @extends {FxRenderer}
 */
export class FxMailListRenderer extends FxRenderer {
    /**
     * Creates an instance of FxMailListRenderer.
     *
     * @memberof FxMailListRenderer
     */
    public constructor() {
        super();
    }

    /**
     * Renderer method
     *
     * @param {string} context
     * @param {FxMailList} container
     * @memberof FxMailListRenderer
     */
    public override render(context: string, container: FxMailList): void {
        const noDataLabel = container.noDataLabel;

        const spinnerElement = document.createElement('div');
        spinnerElement.classList.value = 'mail-item spinner full-height';
        spinnerElement.style.display = 'none';
        spinnerElement.innerHTML = '<fx-spinner class="small"></fx-spinner>';

        if (container.setDataModelCounter === 0) {
            // First renderer before setting up data
            container.innerHTML = `<div class="mail-list">${spinnerElement.outerHTML}</div>`;
        } else if (container.setDataModelCounter === 1) {
            // First renderer when the first set of data has been setup
            if (container.model.length > 0) {
                container.innerHTML = '';
                const mailListElement = this.generateMailList(
                    container.viewMode,
                    container.model,
                    container
                );
                spinnerElement.style.display = 'none';
                spinnerElement.classList.remove('full-height');
                mailListElement.appendChild(spinnerElement);
                container.appendChild(mailListElement);
            } else {
                // If data array is empty from the first time, data is not available
                container.innerHTML = `<div class="mail-list"><span class="error-message">${noDataLabel}</span></div>`;
            }
        } else {
            // Further renderers in case of lazy loading
            if (container.addedModel && container.addedModel.length > 0) {
                const addedMailListElement = this.generateMailList(
                    container.viewMode,
                    container.addedModel,
                    container
                );
                Array.from(addedMailListElement.children).forEach((element) => {
                    container.listElement.insertBefore(
                        element,
                        container.listElement.getElementsByClassName('mail-item spinner')[0]
                    );
                });
            }
        }
    }

    /**
     * Generate the mail list element as HTMLElement
     *
     * @private
     * @param {*} viewMode
     * @param {*} mailList
     * @return {*}  {HTMLElement}
     * @memberof FxMailListRenderer
     */
    private generateMailList(viewMode, mailList, containerRef): HTMLElement {
        // Mail list container
        const mailListElement: HTMLElement = document.createElement('div');
        mailListElement.classList.add('mail-list');

        const noSubjectLabel = containerRef.noSubjectLabel;

        /*
        // Thread view mode temporarely disabled
        if (viewMode === 'thread') {
            // Thread grouping view
            const threadMap = mailList.reduce((hash, obj) => {
                if (obj['conversationId'] === undefined) {
                    return hash;
                }
                return Object.assign(hash, {
                    [obj['conversationId']]: (hash[obj['conversationId']] || []).concat(obj)
                });
            }, {});

            Object.keys(threadMap).forEach((key) => {
                const threadMailList = threadMap[key] || [];

                if (threadMailList.length === 1) {
                    // Plain mail
                    const tempMailTemplate = document.createElement('div');
                    tempMailTemplate.innerHTML = this.emailTemplate(
                        threadMailList[0].sender.name,
                        this.formatDateTime(
                            threadMailList[0].receivedDateTime,
                            containerRef.dateFormat
                        ),
                        threadMailList[0].subject,
                        threadMailList[0].bodyPreview,
                        threadMailList[0].hasAttachments
                    );

                    // Event listener for selection
                    const mail = tempMailTemplate.getElementsByClassName('mail-item')[0];
                    if (mail) {
                        mail.addEventListener('click', () => {
                            this.resetActiveStatus(containerRef);
                            this.resetExpandedStatus(containerRef);
                            mail.classList.add('active');
                            containerRef.onItemClickCallback(threadMailList[0], () => true);
                        });
                    }

                    const weblink =
                        tempMailTemplate.getElementsByClassName('mail-item-row-weblink')[0];
                    if (weblink) {
                        weblink.addEventListener('click', (event) => {
                            containerRef.onLinkClickCallback(threadMailList[0], () => true);
                            event.stopPropagation();
                        });
                    }

                    mailListElement.appendChild(tempMailTemplate.children[0]);
                } else if (threadMailList.length > 1) {
                    // Thread
                    const thread: HTMLElement = document.createElement('div');
                    thread.className = 'thread-item';

                    const tempThreadTemplate = document.createElement('div');
                    tempThreadTemplate.innerHTML = this.threadTemplate(
                        threadMailList[0].sender.name,
                        this.formatDateTime(
                            threadMailList[0].receivedDateTime,
                            containerRef.dateFormat
                        ),
                        threadMailList[0].subject,
                        threadMailList[0].bodyPreview,
                        threadMailList[0].hasAttachments
                    );
                    thread.appendChild(tempThreadTemplate.children[0]);

                    // Thread children
                    threadMailList.forEach((obj, index) => {
                        const tempChildTemplate = document.createElement('div');
                        tempChildTemplate.innerHTML = this.threadChildTemplate(
                            obj.sender.name,
                            this.formatDateTime(obj.receivedDateTime, containerRef.dateFormat),
                            obj.bodyPreview,
                            obj.hasAttachments
                        );

                        // Event listener for selection
                        const child = tempChildTemplate.getElementsByClassName('mail-item')[0];
                        if (child) {
                            child.addEventListener('click', () => {
                                this.resetActiveStatus(containerRef);
                                thread.classList.add('active');
                                child.classList.add('active');
                                containerRef.onItemClickCallback(threadMailList[index], () => true);
                            });
                        }

                        thread.appendChild(tempChildTemplate.children[0]);
                    });

                    // Event listener for expand/collaps and selection
                    thread
                        .getElementsByClassName('mail-item thread')[0]
                        .addEventListener('click', () => {
                            this.resetActiveStatus(containerRef);
                            this.resetExpandedStatus(containerRef);

                            thread.classList.add('active');
                            thread.classList.add('expanded');

                            // Select the first child
                            thread
                                .getElementsByClassName('thread-child')[0]
                                .classList.add('active');

                            containerRef.onItemClickCallback(threadMailList[0], () => true);
                        });

                    thread
                        .getElementsByClassName('mail-item-row-counter')[0]
                        .addEventListener('click', (event) => {
                            this.resetExpandedStatus(containerRef);
                            thread.classList.toggle('expanded');
                            event.stopPropagation();
                        });

                    mailListElement.appendChild(thread);
                }
            });
        } else {
        */
        // Flat list view as default
        mailList.forEach((mail) => {
            const tempMailTemplate = document.createElement('div');
            tempMailTemplate.innerHTML = this.emailTemplate(
                mail.sender.name,
                this.formatDateTime(mail.receivedDateTime, containerRef.dateFormat),
                mail.subject && mail.subject.length > 0 ? mail.subject : noSubjectLabel,
                mail.bodyPreview,
                mail.hasAttachments,
                mail.marker,
                containerRef
            );

            // Event listener for email activation
            const flatMail = tempMailTemplate.getElementsByClassName('mail-item')[0];
            if (flatMail) {
                // Standard activation with item click
                flatMail.addEventListener('click', () => {
                    if (containerRef.activation === true) {
                        this.resetActiveStatus(containerRef);
                        this.resetExpandedStatus(containerRef);
                        flatMail.classList.add('active');
                    }
                    containerRef.onItemClickCallback(mail, () => true);
                });
            }

            // Event listener for weblink click
            const weblink = tempMailTemplate.getElementsByClassName('mail-item-row-weblink')[0];
            if (weblink) {
                weblink.addEventListener('click', (event) => {
                    containerRef.onLinkClickCallback(mail, () => true);
                    event.stopPropagation();
                });
            }

            // Event listener for checkbox click
            const checkbox = tempMailTemplate.getElementsByClassName('fx-check-box')[0];
            if (checkbox) {
                checkbox.addEventListener('click', (event) => {
                    this.selectItem(containerRef, mail);
                    containerRef.onCheckboxClickCallback(containerRef.selectedItems, () => true);
                    event.stopPropagation();
                });
            }

            // Event listener for view click
            if (containerRef.viewAction) {
                const view = tempMailTemplate.getElementsByClassName('mail-item-row-view')[0];
                if (view) {
                    view.addEventListener('click', (event) => {
                        containerRef.onViewClickCallback(mail, () => true);
                        event.stopPropagation();
                    });
                }
            }

            mailListElement.appendChild(tempMailTemplate.children[0]);
        });
        // }
        return mailListElement;
    }

    /**
     * Provide html template for plain email boxes
     *
     * @private
     * @param {*} from
     * @param {*} receivedDateTime
     * @param {*} subject
     * @param {*} preview
     * @param {*} hasAttachments
     * @memberof FxMailListRenderer
     */

    private emailTemplate = (
        from,
        receivedDateTime,
        subject,
        preview,
        hasAttachments,
        marker,
        containerRef
    ): string => /* html */ `
            <div class="mail-item">
                <div class="mail-item-col">
                </div>
                <div class="mail-item-col">
                    <div class="mail-item-row">
                        <div class="mail-item-row-subject">
                            <span>${subject}</span>
                        </div>
                        ${
                            marker
                                ? `
                        <div class="mail-item-row-marker">
                            <i class="mdi ${marker}" title="${containerRef.markerTitle}"></i>
                        </div>
                        `
                                : ''
                        }
                        ${
                            hasAttachments
                                ? `
                        <div class="mail-item-row-attachment">
                            <i class="mdi mdi-paperclip"></i>
                        </div>
                        `
                                : ''
                        }
                        ${
                            containerRef.viewAction
                                ? `
                        <div class="mail-item-row-view">
                            <i class="mdi ${containerRef.viewButtonClass}" title="${containerRef.viewTitle}"></i>
                        </div>
                        `
                                : ''
                        }
                        <div class="mail-item-row-weblink">
                            <i class="mdi mdi-open-in-new" title="${containerRef.weblinkTitle}"></i>
                        </div>
                        ${
                            containerRef.multipleSelection
                                ? `
                                <div class="mail-item-row-checkbox">
                                    <label class="fx-check-box no-label">
                                        <input type="checkbox" onclick="event.stopPropagation()">
                                        <span></span>
                                    </label>
                                </div>
                        `
                                : ''
                        }
                    </div>
                    <div class="mail-item-row">
                        <div class="mail-item-row-from">
                            <span>${from}</span>
                        </div>
                    </div>

                    <div class="mail-item-row">
                        <div class="mail-item-row-date-time">
                            <span>${receivedDateTime}</span>
                        </div>
                    </div>

                    <div class="mail-item-row">
                        <div class="mail-item-row-preview">
                            <span>${preview}</span>
                        </div>
                    </div>
                </div>
            </div>
        `;

    /**
     * Provide html template for email thread boxes
     *
     * @private
     * @param {*} from
     * @param {*} receivedDateTime
     * @param {*} subject
     * @param {*} preview
     * @memberof FxMailListRenderer
     */
    // private threadTemplate = (
    //     from,
    //     receivedDateTime,
    //     subject,
    //     preview,
    //     hasAttachments
    // ): string => /* html */ `
    //         <div class="mail-item thread">
    //             <div class="mail-item-col">
    //                 <div class="mail-item-row">
    //                     <div class="mail-item-row-counter">
    //                         <i class="mdi mdi-chevron-down open"></i>
    //                         <i class="mdi mdi-chevron-right close"></i>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="mail-item-col">
    //                 <div class="mail-item-row">
    //                     <div class="mail-item-row-subject">
    //                         <span>${subject}</span>
    //                     </div>
    //                     ${
    //                         hasAttachments
    //                             ? `
    //                     <div class="mail-item-row-attachment">
    //                         <i class="mdi mdi-paperclip"></i>
    //                     </div>
    //                     `
    //                             : ''
    //                     }
    //                 </div>
    //                 <div class="mail-item-row">
    //                     <div class="mail-item-row-from">
    //                         <span>${from}</span>
    //                     </div>
    //                 </div>
    //                 <div class="mail-item-row">
    //                     <div class="mail-item-row-date-time">
    //                         <span>${receivedDateTime}</span>
    //                     </div>
    //                 </div>
    //                 <div class="mail-item-row">
    //                     <div class="mail-item-row-preview">
    //                         <span>${preview}</span>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     `;

    /**
     * Provide html template for thread children boxes
     *
     * @private
     * @param {*} from
     * @param {*} receivedDateTime
     * @param {*} preview
     * @param {*} hasAttachments
     * @memberof FxMailListRenderer
     */
    // private threadChildTemplate = (from, receivedDateTime, preview, hasAttachments): string => `
    //         <div class="mail-item thread-child">
    //             <div class="mail-item-col">
    //             </div>
    //             <div class="mail-item-col">
    //                 <div class="mail-item-row">
    //                     <div class="mail-item-row-from">
    //                         <span>${from}</span>
    //                     </div>
    //                     ${
    //                         hasAttachments
    //                             ? `
    //                     <div class="mail-item-row-attachment">
    //                         <i class="mdi mdi-paperclip"></i>
    //                     </div>
    //                     `
    //                             : ''
    //                     }
    //                     <div class="mail-item-row-date-time">
    //                         <span>${receivedDateTime}</span>
    //                     </div>
    //                 </div>
    //                 <div class="mail-item-row">
    //                     <div class="mail-item-row-preview">
    //                         <span>${preview}</span>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     `;

    /**
     * Reset active status for the list
     *
     * @private
     * @param {FxMailList} container
     * @param {boolean} [excludeThread=false]
     * @memberof FxMailListRenderer
     */
    private resetActiveStatus = (container: FxMailList, excludeThread: boolean = false): void => {
        if (excludeThread) {
            Array.from(container.listElement.querySelectorAll('.active:not(.thread-item)')).forEach(
                (activeItem) => {
                    activeItem.classList.remove('active');
                }
            );
        } else {
            Array.from(container.listElement.getElementsByClassName('active')).forEach(
                (activeItem) => {
                    activeItem.classList.remove('active');
                }
            );
        }
    };

    /**
     * Reset expanded status for the thread objects
     *
     * @private
     * @param {FxMailList} container
     * @memberof FxMailListRenderer
     */
    private resetExpandedStatus = (container: FxMailList): void => {
        Array.from(container.listElement.getElementsByClassName('expanded')).forEach(
            (activeItem) => {
                activeItem.classList.remove('expanded');
            }
        );
    };

    /**
     * Format date time with moment.js
     *
     * @private
     * @param {string} date
     * @param {string} format
     * @memberof FxMailListRenderer
     */
    private formatDateTime = (date: string, format: string): string => {
        // return moment(date).format(format);
        return moment(new Date(date)).format(format);
    };

    /**
     * Manage the multiple selection
     *
     * @private
     * @param {FxMailList} container
     * @param {FxEmailPreview} mail
     * @memberof FxMailListRenderer
     */
    private selectItem = (container: FxMailList, mail: FxEmailPreview): void => {
        if (container.selectedItems.includes(mail)) {
            // Remove selection
            container.selectedItems.splice(container.selectedItems.indexOf(mail), 1);
        } else {
            // Add selection
            container.selectedItems.push(mail);
        }
    };
}
