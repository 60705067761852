import { FxElement, FxElements } from '@finantix/core';
import { FxMultiListItem } from './fx-multi-list-item';
import { FxMultiListRenderer } from './fx-multi-list.renderer';
import * as i18n from './locales/locale.en.json';

@FxElements.register({
    selector: 'fx-multi-list',
    i18n,
    renderer: new FxMultiListRenderer()
})
export class FxMultiList extends FxElement {
    public static get observedAttributes(): string[] {
        return [...FxElement.observedAttributes, 'sortable', 'min-items', 'max-items', 'grid'];
    }

    // Configuration options
    private _sortable: boolean;
    private _minItems: number;
    private _maxItems: number;
    private _grid: string | null;
    // Internal HTML references
    private _childrenObserver: MutationObserver;

    public readonly DRAG_HANDLE_CLASS = 'drag-handle';

    public constructor() {
        super();
    }

    public get isSortable(): boolean {
        return this._sortable;
    }

    public get minItems(): number {
        return this._minItems;
    }

    public get maxItems(): number {
        return this._maxItems;
    }

    public get grid(): string | null {
        return this._grid;
    }

    public get itemsKeys(): string[] {
        const items = this.querySelectorAll('fx-multi-list-item[key]');
        const keys = Array.from(items).map((item) => item.getAttribute('key') || '');
        return keys.filter((key) => key !== '');
    }

    public onConnected(): void {
        this._sortable = this.getAttributeBool('sortable', false);
        this._minItems = this.getAttributeNumber('min-items', 0);
        this._maxItems = this.getAttributeNumber('max-items', -1);
        this._grid = this.getAttribute('grid');

        this.watchChildren();

        super.onConnected();
    }

    public onAttributeChanged(attr: any, oldVal: any, newVal: any): void {
        if (this.initialized && oldVal !== newVal) {
            switch (attr) {
                case 'sortable':
                    this._sortable = newVal === 'true';
                    break;
                case 'min-items':
                    this._minItems = parseInt(newVal, 10);
                    break;
                case 'max-items':
                    this._maxItems = parseInt(newVal, 10);
                    break;
                case 'grid':
                    this._grid = newVal;
                    break;
            }
        }
    }

    public onDisconnected(): void {
        this._childrenObserver.disconnect();
    }

    private watchChildren(): void {
        this._childrenObserver = new MutationObserver((mutations: MutationRecord[]) => {
            mutations.forEach((mutation) => {
                if (mutation.target instanceof HTMLElement) {
                    Array.from(mutation.target.children).forEach((item) => {
                        if (item instanceof FxMultiListItem) {
                            item.repaint();
                        }
                    });
                }
            });
        });

        this._childrenObserver.observe(this, {
            childList: true,
            subtree: false
        });
    }
}
