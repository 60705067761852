import TomSelect from 'tom-select/dist/esm/tom-select';
import 'tom-select/dist/esm/plugins/checkbox_options/plugin';

//This plugin is used to fix the display of the dropdown menu using popper.js library since setting the display of the dropdown to none doesn't let popper to calculate the new position of the dropdown menu.

TomSelect.define('popper_fix', function (this: TomSelect) {
    this.hook('after', 'close', () => {
        this.dropdown.style.display = 'block';
        this.dropdown.style.visibility = 'hidden';
    });
});