import '@vaadin/vaadin-notification';
import { FxElement, FxElements } from "@finantix/core";
import { FxA11yRenderer } from "./fx-a11y.renderer";
import * as i18n from './locales/locale.en.json';

@FxElements.register({
    selector: 'fx-a11y',
    i18n,
    renderer: new FxA11yRenderer()
})

export class FxA11y extends FxElement {
    constructor() {
        super();
    }

    onConnected() {
        super.onConnected();
    }

    public onKeyDown(e: KeyboardEvent) {
        if (e.shiftKey && e.altKey) {
            switch (e.code) {
                case 'KeyL': {
                    e.preventDefault();
                    this.focusLeftSide();
                    break;
                }
                case 'KeyH': {
                    e.preventDefault();
                    this.focusHeader();
                    break;
                }
                case 'KeyC': {
                    e.preventDefault();
                    this.focusContent();
                    break;
                }
                case 'KeyR': {
                    e.preventDefault();
                    this.focusRightSide();
                    break;
                }
            }
        }
    }

    private getFocusableNodeListIn(
        root: HTMLElement,
        moveToFirst: boolean = true
    ): TreeWalker {
        const tw: TreeWalker = document.createTreeWalker(
            root,
            NodeFilter.SHOW_ELEMENT,
            {
                acceptNode: (
                    node: HTMLElement
                ) => node.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP
            }
        );

        if (moveToFirst) {
            tw.nextNode();
        }
        return tw;
    }

    private focusCurrentNode(parent) {
        let listNode: TreeWalker = this.getFocusableNodeListIn(parent);
        let node: HTMLElement = listNode.currentNode as HTMLElement;
        node.focus();
    }

    private focusLeftSide() {
        let leftSide: HTMLElement = document.querySelector('.fx-page-sidebar-left') as HTMLElement;
        if (leftSide) {
            this.focusCurrentNode(leftSide);
        }
    }

    private focusHeader() {
        let header: HTMLElement = document.querySelector('.fx-main-header') as HTMLElement;
        if (header) {
            this.focusCurrentNode(header);
        }
    }

    private focusContent() {
        let content: HTMLElement = document.querySelector('.fx-page-body') as HTMLElement;
        if (content) {
            this.focusCurrentNode(content);
        }
    }

    private focusRightSide() {
        let rightSide: HTMLElement = document.querySelector('.fx-page-sidebar-right') as HTMLElement;
        if (rightSide) {
            this.focusCurrentNode(rightSide);
        }
    }
}