import { FxRenderer, FxHTMLElement } from '@finantix/core';
import type {
    FxDropdown,
    FxDropdownOpenEvent,
    FxDropdownCloseEvent
} from '../../fx-dropdown';
import {
    FxMultiListItemRemovedEvent,
    FxMultiListItemRemoveEvent,
    FxMultiListItemMenuOpenEvent,
    FxMultiListItemMenuCloseEvent
} from '../fx-multi-list.events';
import type { FxMultiListItem } from './fx-multi-list-item';

export class FxMultiListItemRenderer extends FxRenderer {
    public constructor() {
        super();
    }

    public render(_context: string, item: FxMultiListItem): void {
        switch (item.currentPhase) {
            case 'FIRST_PAINTING':
                {
                    item.classList.toggle('fx-multi-list-item', true);
                    item.innerHTML = `
                    ${this.createDragHandle(item)}
                    <div class="data-wrapper">
                        ${this.createSpecialMarker(item)}
                    </div>
                    ${this.createActions(item, item.menu)}
                `;

                    this.insertData(item, item.data);
                    this.insertMenu(item, item.menu);

                    this.attachMenuEventsHandlers(item);
                }
                break;
            case 'REPAINTING':
                {
                    if (item.actions) {
                        if (item.menu) {
                            FxHTMLElement.detachElement(item.menu);
                        }
                        item.removeChild(item.actions);
                    }

                    const temp = document.createElement('div');
                    temp.innerHTML = this.createActions(item, item.menu);
                    Array.from(temp.children).forEach((node) => item.appendChild(node));
                    this.insertMenu(item, item.menu);
                }
                break;
        }
    }

    private attachMenuEventsHandlers(item: FxMultiListItem): void {
        if (item.menu) {
            item.menu.onOpen((event: FxDropdownOpenEvent) => {
                if (item.parent) {
                    item.parent.dispatchEvent(
                        new FxMultiListItemMenuOpenEvent({
                            item: item,
                            menu: event.detail.dropdown
                        })
                    );
                }
            });
            item.menu.onClose((event: FxDropdownCloseEvent) => {
                if (item.parent) {
                    item.parent.dispatchEvent(
                        new FxMultiListItemMenuCloseEvent({
                            item: item,
                            menu: event.detail.dropdown
                        })
                    );
                }
            });
        }
    }

    private insertData(item: FxMultiListItem, data: HTMLElement[]): void {
        const dataWrapper: HTMLElement | null = item.dataWrapper;
        if (dataWrapper) {
            data.forEach((element) => dataWrapper.appendChild(element));
        }
    }

    private insertMenu(item: FxMultiListItem, menu: FxDropdown | null): void {
        const actions: HTMLElement | null = item.actions;
        const actionButton: HTMLElement | null = item.actionButton;
        if (actions && actionButton) {
            if (menu) {
                this.toggleRemoveInsideMenu(item, menu);
                this.createDropdown(actions, actionButton, menu);
            } else {
                this.addRemoveBehaviourTo(actionButton, item);
            }
        }
    }

    private createDropdown(parent: HTMLElement, trigger: HTMLElement, content: FxDropdown): void {
        content.triggerElement = trigger;
        parent.appendChild(content);
    }

    private toggleRemoveInsideMenu(item: FxMultiListItem, menu: FxDropdown): void {
        const menuItemRemove: HTMLElement | null = menu.querySelector('.remove-btn');
        if (!menuItemRemove && item.shouldShowRemoveButton()) {
            const itemRemove: HTMLElement = document.createElement('a');
            itemRemove.setAttribute('href', 'javascript:void(0)');
            itemRemove.classList.add('dropdown-item');
            itemRemove.classList.add('remove-btn');
            itemRemove.innerHTML = item.removeText;
            this.addRemoveBehaviourTo(itemRemove, item);
            menu.appendChild(itemRemove);
        } else if (menuItemRemove && !item.shouldShowRemoveButton()) {
            FxHTMLElement.detachElement(menuItemRemove);
        }
    }

    private addRemoveBehaviourTo(element: HTMLElement, item: FxMultiListItem): void {
        element.addEventListener('click', () => {
            if (item.parent) {
                const multiList = item.parent;
                const canRemoveItem: boolean = multiList.dispatchEvent(
                    new FxMultiListItemRemoveEvent({ item })
                );
                if (canRemoveItem) {
                    multiList.removeChild(item);
                    multiList.dispatchEvent(new FxMultiListItemRemovedEvent({ item }));
                }
            }
        });
    }

    private getActionButtonHint(item: FxMultiListItem, menu: FxDropdown | null): string {
        let translationKey = '';
        switch (true) {
            case item.isRemovable && !menu:
                translationKey = 'fx-multi-list.menu.remove.label';
                break;
            case menu != null:
                translationKey = 'fx-multi-list.menu.menu.label';
                break;
        }

        return item.translateContent(translationKey);
    }

    private createSpecialMarker(item: FxMultiListItem): string {
        return item.showSpecialMarker ? '<div class="triangle-marker"></div>' : '';
    }

    private createDragHandle(item: FxMultiListItem): string {
        return item.parent && item.parent.isSortable && !item.isLocked
            ? `<button class="${item.parent.DRAG_HANDLE_CLASS}">
                <i aria-hidden="true" class="mdi mdi-arrow-all"></i>
            </button>`
            : '';
    }

    private createActions(item: FxMultiListItem, menu: FxDropdown | null): string {
        return (menu && menu.children.length > 0) || item.shouldShowRemoveButton()
            ? `
            <div class="actions">
                <button
                    aria-label="${this.getActionButtonHint(item, menu)}"
                    title="${this.getActionButtonHint(item, menu)}"
                    ${menu ? 'aria-expanded="false"' : ''}
                    ${menu ? 'aria-haspopup="true"' : ''}
                    class="action-button ${menu ? 'menu-toggle' : 'remove-btn'}"
                >
                    ${
                        !menu && item.shouldShowRemoveButton()
                            ? `
                        <i aria-hidden="true" class="mdi mdi-delete-outline"></i>
                    `
                            : `
                        <i aria-hidden="true" class="mdi mdi-dots-vertical"></i>
                    `
                    }
                </button>
            </div>
        `
            : '';
    }
}
