import { TableCell, TableCellType } from '../models';
import { FxTableCellRenderer } from './table-cell.renderer';

export class FxTableDefaultCellRenderer extends FxTableCellRenderer {
    public render(cell: TableCell, cellContainer: HTMLElement): void {
        if (cell.type === TableCellType.Text) {
            cellContainer.innerText = (cell.content as string) || '';
        } else if (cell.type === TableCellType.Html) {
            cellContainer.innerHTML = (cell.content as string) || '';
        }
    }
}
