import type { FxTable } from '../core/table';

export enum ToolbarItemActionType {
    Customization = 'customization',
    Layout = 'layout',
    Content = 'content'
}

export interface ToolbarItem {
    icon?: any;
    iconClass?: string;
    title?: string;
    action: string;
    actionType?: string;
    type?: string;
    selectionRequired?: boolean;
    params?: any;

    renderer?: (component: FxTable, item: ToolbarItem) => HTMLElement;
}
