import { fxWebComponentEventFactory } from '@finantix/core';
import type { FxSelect } from './fx-select';

export class FxSelectDropdownOpenEvent extends fxWebComponentEventFactory<{
    dropdown: HTMLElement
}>(
    'fx-select:open'
) { }

export class FxSelectDropdownCloseEvent extends fxWebComponentEventFactory<{
    dropdown: HTMLElement
}>(
    'fx-select:close'
) { }

export class FxSelectItemSelectEvent extends fxWebComponentEventFactory<{
    select: FxSelect,
    item: string
}>(
    'fx-select:item_select'
) { }

export class FxSelectItemRemoveEvent extends fxWebComponentEventFactory<{
    select: FxSelect
    item: string
}>(
    'fx-select:item_remove'
) { }

export class FxSelectClearEvent extends fxWebComponentEventFactory<{
    select: FxSelect
}>(
    'fx-select:clear'
) { }
