/**
 * Convert library value to the coma separated format used by this wrapper
 *
 * @public
 * @param {(string | string[])} value value from the library
 * @returns {*}  {string} coma-separated value
 * @memberof FxSelect
 */
export const parseLibraryValue = (value: string | string[]): string => {
    return typeof value === 'string' ? value : value.filter((token) => token !== '').join(',');
};
