import type { TableCell } from '../models';
import { FxTableCellRenderer } from './table-cell.renderer';

export class FxTableImageCellRenderer extends FxTableCellRenderer {
    public render(cell: TableCell, cellContainer: HTMLElement): void {
        const img = document.createElement('img') as HTMLImageElement;
        img.src = cell.content;
        cellContainer.appendChild(img);
    }
}
