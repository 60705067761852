import type { TableItem } from './table-item.model';

export enum TableCellType {
    Text = 'text',
    Image = 'image',
    Html = 'html'
}

export interface TableCell extends TableItem {
    type: string;
    colSpan?: number;
    rowSpan?: number;
    selected?: boolean;
    hide?: boolean;
}
