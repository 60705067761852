import { FxElements } from '@finantix/core';
import { AddTableColumnSide, AddTableRowSide, FxTable } from '../core/table';
import {
    TableCell,
    TableCellType,
    TableColumn,
    TableLayoutModifier,
    TableModel,
    TableRow
} from '../models';
import type { ToolbarGroup } from '../models/toolbar-group.model';
import { ToolbarItemActionType } from '../models/toolbar-item.model';
import {
    AddColumnOnLeftIconUrl,
    AddColumnOnRightIconUrl,
    AddRowOnBottomIconUrl,
    AddRowOnTopIconUrl,
    AlignCenterIconUrl,
    AlignJustifyIconUrl,
    AlignLeftIconUrl,
    AlignRightIconUrl,
    BgColorIconUrl,
    BoldIconUrl,
    ImageIconUrl,
    ItalicIconUrl,
    MergeCellsIconUrl,
    RemoveColumnIconUrl,
    RemoveRowIconUrl,
    SplitCellsIconUrl,
    TextColorIconUrl,
    UnderlineIconUrl
} from './table-icons';

export class FxTableUtils {
    public static DEFAULT_NO_OF_ROWS = 4;
    public static DEFAULT_NO_OF_COLS = 4;
    public static DEFAULT_COLUMN_WIDTH = 200;
    public static MIN_COLUMN_WIDTH = 40;
    public static DO_NOT_BREAK_SMALL_TABLES_MAX_ROWS = 3;

    public static getDefaultModel(): TableModel {
        const columns: TableColumn[] = [];
        for (let j = 0; j < FxTableUtils.DEFAULT_NO_OF_COLS; j++) {
            const width = FxTableUtils.DEFAULT_COLUMN_WIDTH;
            columns.push({
                width
            });
        }

        const rows: TableRow[] = [];
        for (let i = 0; i < FxTableUtils.DEFAULT_NO_OF_ROWS; i++) {
            const cells: TableCell[] = [];
            for (let j = 0; j < FxTableUtils.DEFAULT_NO_OF_COLS; j++) {
                cells.push({
                    type: TableCellType.Text
                });
            }
            rows.push({
                cells
            });
        }

        return {
            columns,
            rows
        };
    }

    public static getDefaultInlineStylesMap(): { [key: string]: string } {
        return {
            bold: 'font-weight: bold',
            italic: 'font-style: italic',
            underline: 'text-decoration: underline',
            left: 'text-align: left',
            right: 'text-align: right',
            center: 'text-align: center',
            justify: 'text-align: justify'
        };
    }

    public static getDefaultToolbarItems(table: FxTable): ToolbarGroup[] {
        return [
            {
                items: [
                    {
                        icon: BoldIconUrl,
                        title: FxElements.translate(table, 'shared.utils.common.fx-table.bold'),
                        action: 'bold',
                        actionType: ToolbarItemActionType.Customization,
                        selectionRequired: true
                    },
                    {
                        icon: ItalicIconUrl,
                        title: FxElements.translate(table, 'shared.utils.common.fx-table.italic'),
                        action: 'italic',
                        actionType: ToolbarItemActionType.Customization,
                        selectionRequired: true
                    },
                    {
                        icon: UnderlineIconUrl,
                        title: FxElements.translate(
                            table,
                            'shared.utils.common.fx-table.underline'
                        ),
                        action: 'underline',
                        actionType: ToolbarItemActionType.Customization,
                        selectionRequired: true
                    }
                ]
            },
            {
                items: [
                    {
                        icon: AlignLeftIconUrl,
                        title: FxElements.translate(
                            table,
                            'shared.utils.common.fx-table.align-left'
                        ),
                        action: 'left',
                        actionType: ToolbarItemActionType.Customization,
                        selectionRequired: true
                    },
                    {
                        icon: AlignRightIconUrl,
                        title: FxElements.translate(
                            table,
                            'shared.utils.common.fx-table.align-right'
                        ),
                        action: 'right',
                        actionType: ToolbarItemActionType.Customization,
                        selectionRequired: true
                    },
                    {
                        icon: AlignCenterIconUrl,
                        title: FxElements.translate(
                            table,
                            'shared.utils.common.fx-table.align-center'
                        ),
                        action: 'center',
                        actionType: ToolbarItemActionType.Customization,
                        selectionRequired: true
                    },
                    {
                        icon: AlignJustifyIconUrl,
                        title: FxElements.translate(
                            table,
                            'shared.utils.common.fx-table.align-justify'
                        ),
                        action: 'justify',
                        actionType: ToolbarItemActionType.Customization,
                        selectionRequired: true
                    }
                ]
            },
            {
                items: [
                    {
                        icon: TextColorIconUrl,
                        title: FxElements.translate(
                            table,
                            'shared.utils.common.fx-table.text-xolor'
                        ),
                        action: 'fg-color',
                        type: 'fg-color-picker',
                        actionType: ToolbarItemActionType.Customization,
                        selectionRequired: true,
                        params: ['#ff0000', '#00ff00', '#0000ff']
                    },
                    {
                        icon: BgColorIconUrl,
                        title: FxElements.translate(
                            table,
                            'shared.utils.common.fx-table.background-color'
                        ),
                        action: 'bg-color',
                        actionType: ToolbarItemActionType.Customization,
                        type: 'bg-color-picker',
                        selectionRequired: true,
                        params: ['#ff0000', '#00ff00', '#0000ff']
                    }
                ]
            },
            {
                items: [
                    {
                        icon: ImageIconUrl,
                        title: FxElements.translate(table, 'shared.utils.common.fx-table.image'),
                        action: 'image',
                        actionType: ToolbarItemActionType.Content,
                        selectionRequired: true
                    }
                ]
            },
            {
                items: [
                    {
                        icon: AddRowOnTopIconUrl,
                        title: FxElements.translate(
                            table,
                            'shared.utils.common.fx-table.add-row-on-top'
                        ),
                        action: 'add-row-on-top',
                        actionType: ToolbarItemActionType.Layout
                    },
                    {
                        icon: AddRowOnBottomIconUrl,
                        title: FxElements.translate(
                            table,
                            'shared.utils.common.fx-table.add-row-on-bottom'
                        ),
                        action: 'add-row-on-bottom',
                        actionType: ToolbarItemActionType.Layout
                    },
                    {
                        icon: RemoveRowIconUrl,
                        title: FxElements.translate(
                            table,
                            'shared.utils.common.fx-table.remove-row'
                        ),
                        action: 'remove-row',
                        actionType: ToolbarItemActionType.Layout
                    },
                    {
                        icon: AddColumnOnLeftIconUrl,
                        title: FxElements.translate(
                            table,
                            'shared.utils.common.fx-table.add-column-on-left'
                        ),
                        action: 'add-column-on-left',
                        actionType: ToolbarItemActionType.Layout
                    },
                    {
                        icon: AddColumnOnRightIconUrl,
                        title: FxElements.translate(
                            table,
                            'shared.utils.common.fx-table.add-column-on-right'
                        ),
                        action: 'add-column-on-right',
                        actionType: ToolbarItemActionType.Layout
                    },
                    {
                        icon: RemoveColumnIconUrl,
                        title: FxElements.translate(
                            table,
                            'shared.utils.common.fx-table.remove-column'
                        ),
                        action: 'remove-column',
                        actionType: ToolbarItemActionType.Layout
                    }
                ]
            },
            {
                items: [
                    {
                        icon: MergeCellsIconUrl,
                        title: FxElements.translate(table, 'shared.utils.common.fx-table.merge'),
                        action: 'merge',
                        actionType: ToolbarItemActionType.Layout,
                        selectionRequired: true
                    },
                    {
                        icon: SplitCellsIconUrl,
                        title: FxElements.translate(table, 'shared.utils.common.fx-table.split'),
                        action: 'split',
                        actionType: ToolbarItemActionType.Layout,
                        selectionRequired: true
                    }
                ]
            }
        ];
    }

    public static getAppliedLayoutModifiers(
        component: FxTable,
        modifier: TableLayoutModifier
    ): string[] {
        const result: string[] = [];

        component.tableModel.rows.forEach((row: TableRow) => {
            row.cells.forEach((cell: TableCell) => {
                if (cell.selected) {
                    if (cell.layout && cell.layout[modifier]) {
                        if (typeof cell.layout[modifier] === 'string') {
                            if (!result.includes(cell.layout[modifier.toString()])) {
                                result.push(cell.layout[modifier.toString()]);
                            }
                        } else {
                            cell.layout[modifier.toString()].forEach((item: string) => {
                                if (!result.includes(item)) {
                                    result.push(item);
                                }
                            });
                        }
                    }
                }
            });
        });

        return result;
    }

    public static addRow(component: FxTable, side: AddTableRowSide, indexes?: number[]): void {
        if (!indexes) {
            indexes = side === AddTableRowSide.Top ? [0] : [component.tableModel.rows.length - 1];
        }

        let i = 0;
        indexes.forEach((index: number) => {
            const row = {
                cells: FxTableUtils._generateCells(component.tableModel)
            };
            const idx = index + i;

            if (side === AddTableRowSide.Top) {
                component.tableModel.rows.splice(idx, 0, row);
            } else {
                component.tableModel.rows.splice(idx + 1, 0, row);
            }
            i++;
        });

        component.repaint();
        component.notifyChange();
    }

    public static removeRow(component: FxTable, indexes?: number[]): void {
        if (indexes !== undefined) {
            const rows: TableRow[] = [];

            component.tableModel.rows.forEach((row: TableRow, rowIndex: number) => {
                if (!indexes.includes(rowIndex)) {
                    rows.push(row);
                }
            });
            component.tableModel.rows = rows;
        } else {
            component.tableModel.rows.pop();
        }

        if (component.tableModel.rows.length === 0) {
            FxTableUtils.addRow(component, AddTableRowSide.Bottom);
        }
        component.repaint();
        component.notifyChange();
    }

    public static addColumn(
        component: FxTable,
        side: AddTableColumnSide,
        indexes?: number[]
    ): void {
        if (!indexes) {
            indexes =
                side === AddTableColumnSide.Left ? [0] : [component.tableModel.columns.length - 1];
        }

        let i = 0;
        indexes.forEach((index: number) => {
            const idx = index + i;
            component.tableModel.rows.forEach((row: TableRow) => {
                if (side === AddTableColumnSide.Left) {
                    row.cells.splice(idx, 0, {
                        type: TableCellType.Text
                    });
                } else {
                    row.cells.splice(idx + 1, 0, {
                        type: TableCellType.Text
                    });
                }
            });
            component.tableModel.columns.push({
                width: FxTableUtils.DEFAULT_COLUMN_WIDTH
            });
            i++;
        });
        component.repaint();
        component.notifyChange();
    }

    public static removeColumn(component: FxTable, indexes?: number[]): void {
        component.tableModel.rows.forEach((row: TableRow) => {
            if (indexes !== undefined) {
                const cells: TableCell[] = [];
                row.cells.forEach((cell: TableCell, colIndex: number) => {
                    if (!indexes.includes(colIndex)) {
                        cells.push(cell);
                    }
                });

                row.cells = cells;
            } else {
                row.cells.pop();
            }
        });

        const columns: TableColumn[] = indexes ? [] : [...component.tableModel.columns];
        if (indexes) {
            component.tableModel.columns.forEach((column: TableColumn, colIndex: number) => {
                if (!indexes.includes(colIndex)) {
                    columns.push(column);
                }
            });
            component.tableModel.columns = columns;
        }

        if (component.tableModel.columns.length === 0) {
            this.addColumn(component, AddTableColumnSide.Right);
        } else {
            component.repaint();
            component.notifyChange();
        }
    }

    public static toggleCellFormatting(component: FxTable, style: string): void {
        component.tableModel.rows.forEach((row: TableRow) => {
            row.cells.forEach((cell: TableCell) => {
                if (cell.selected) {
                    if (!cell.layout) {
                        cell.layout = {};
                    }
                    if (!cell.layout.formattings) {
                        cell.layout.formattings = [];
                    }
                    if (!cell.layout.formattings.includes(style)) {
                        cell.layout.formattings.push(style);
                    } else {
                        const idx = cell.layout.formattings.indexOf(style);
                        cell.layout.formattings.splice(idx, 1);
                    }
                }
            });
        });
        component.repaint();
        component.notifyChange();
    }

    public static setCellContent(component: FxTable, cellType: string, content: any): void {
        FxTableUtils.setTableModelCellContent(component.tableModel, cellType, content);
        component.repaint();
        component.notifyChange();
    }

    public static setTableModelCellContent(
        tableModel: TableModel,
        cellType: string,
        content: any,
        rowId?: number,
        colId?: number
    ): void {
        if (rowId !== undefined && colId !== undefined) {
            tableModel.rows[rowId].cells[colId].type = cellType;
            tableModel.rows[rowId].cells[colId].content = content;
        } else {
            tableModel.rows.forEach((row: TableRow) => {
                row.cells.forEach((cell: TableCell) => {
                    if (cell.selected) {
                        cell.type = cellType;
                        cell.content = content;
                    }
                });
            });
        }
    }

    public static setCellCssClass(component: FxTable, cssClass: string[]): void {
        component.tableModel.rows.forEach((row: TableRow) => {
            row.cells.forEach((cell: TableCell) => {
                if (cell.selected) {
                    if (!cell.layout) {
                        cell.layout = {};
                    }
                    cell.layout.cssClass = cssClass;
                }
            });
        });
        component.repaint();
        component.notifyChange();
    }

    public static setCellStyle(component: FxTable, cssStyle: string): void {
        component.tableModel.rows.forEach((row: TableRow) => {
            row.cells.forEach((cell: TableCell) => {
                if (cell.selected) {
                    if (!cell.layout) {
                        cell.layout = {};
                    }
                    cell.layout.style = cssStyle;
                }
            });
        });
        component.repaint();
        component.notifyChange();
    }

    public static setCellAlignment(component: FxTable, alignment: string): void {
        component.tableModel.rows.forEach((row: TableRow) => {
            row.cells.forEach((cell: TableCell) => {
                if (cell.selected) {
                    if (!cell.layout) {
                        cell.layout = {};
                    }
                    cell.layout.align = alignment;
                }
            });
        });
        component.repaint();
        component.notifyChange();
    }

    public static setCellFgColor(component: FxTable, color: string): void {
        component.tableModel.rows.forEach((row: TableRow) => {
            row.cells.forEach((cell: TableCell) => {
                if (cell.selected) {
                    if (!cell.layout) {
                        cell.layout = {};
                    }
                    cell.layout.fgColor = color;
                }
            });
        });
        component.repaint();
        component.notifyChange();
    }

    public static setCellBgColor(component: FxTable, color: string): void {
        component.tableModel.rows.forEach((row: TableRow) => {
            row.cells.forEach((cell: TableCell) => {
                if (cell.selected) {
                    if (!cell.layout) {
                        cell.layout = {};
                    }
                    cell.layout.bgColor = color;
                }
            });
        });
        component.repaint();
        component.notifyChange();
    }

    public static mergeCells(component: FxTable): void {
        // split first already merged cells
        FxTableUtils.splitCells(component);

        for (let rowIndex = 0; rowIndex < component.tableModel.rows.length; rowIndex++) {
            if (component.firstRowAsHeader && rowIndex === 0) {
                continue;
            }

            for (
                let colIndex = 0;
                colIndex < component.tableModel.rows[rowIndex].cells.length;
                colIndex++
            ) {
                if (component.tableModel.rows[rowIndex].cells[colIndex].selected) {
                    const colSpan = FxTableUtils._getColSpan(component, rowIndex, colIndex);
                    if (colSpan > 0) {
                        FxTableUtils._getColSpan(component, rowIndex, colIndex, true);
                        const rowSpan = FxTableUtils._getRowSpan(
                            component,
                            rowIndex,
                            colIndex,
                            colSpan
                        );

                        if (colSpan > 1) {
                            component.tableModel.rows[rowIndex].cells[colIndex].colSpan = colSpan;
                        }
                        component.tableModel.rows[rowIndex].cells[colIndex].hide = false;

                        if (rowSpan > 1) {
                            component.tableModel.rows[rowIndex].cells[colIndex].rowSpan = rowSpan;
                            component.tableModel.rows[rowIndex].cells[colIndex].hide = false;
                            rowIndex = rowIndex + rowSpan;
                            break;
                        }
                    }

                    colIndex = colIndex + colSpan;
                }
            }
        }

        component.repaint();
        component.notifyChange();
    }

    public static splitCells(component: FxTable): void {
        for (let rowIndex = 0; rowIndex < component.tableModel.rows.length; rowIndex++) {
            for (
                let colIndex = 0;
                colIndex < component.tableModel.rows[rowIndex].cells.length;
                colIndex++
            ) {
                const cell = component.tableModel.rows[rowIndex].cells[colIndex];
                if (cell.selected && (cell.colSpan || cell.rowSpan)) {
                    FxTableUtils._removeSpans(component.tableModel, cell, rowIndex, colIndex);
                }
            }
        }
        component.repaint();
        component.notifyChange();
    }

    public static getSelectedCellsIndexes(
        component: FxTable
    ): Array<{ rowId: number; colId: number }> {
        const cells: Array<{ rowId: number; colId: number }> = [];

        component.tableModel.rows.forEach((row: TableRow, rowId: number) => {
            row.cells.forEach((cell: TableCell, colId: number) => {
                if (cell.selected) {
                    cells.push({
                        rowId,
                        colId
                    });
                }
            });
        });

        return cells;
    }

    public static getSelectedCells(component: FxTable): TableCell[] {
        const cells: TableCell[] = [];

        component.tableModel.rows.forEach((row: TableRow) => {
            row.cells.forEach((cell: TableCell) => {
                if (cell.selected) {
                    cells.push(cell);
                }
            });
        });

        return cells;
    }

    public static calculateTotalColumnsWidths(component: FxTable): number {
        let result = 0;

        component.tableModel.columns.forEach((col: TableColumn) => {
            result = result + col.width;
        });

        return result;
    }

    public static updateColumnsSize(component: FxTable): void {
        const shouldSetWidthOfColumnsInPixels = component.allowHorizontalScrolling;

        if (shouldSetWidthOfColumnsInPixels) {
            FxTableUtils.setColumnsWidthOfHeadersInPixels(component);
        } else {
            FxTableUtils.setColumnsWidthOfHeadersInPercent(component);
        }
    }

    public static pxToPercent(total: number, px: number): number {
        return (px * 100) / total;
    }

    public static verifyIsNestedTable(rows: TableRow[]): boolean {
        const nestedRows = rows.filter((row: TableRow) => row.children);
        return nestedRows.length > 0;
    }

    public static uuid(): string {
        let dt: number = new Date().getTime();
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            /* eslint-disable no-bitwise */
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            /* eslint-disable no-bitwise */
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
    }

    public static openTag(tag: string, ...classesList: string[]): string {
        let html = `<${tag}`;
        html += FxTableUtils.addTagClasses(...classesList);
        return html;
    }

    public static closeOpenTag(): string {
        return '>';
    }

    public static addTagAttributes(...attributes: Array<{ key: string; value: string }>): string {
        let html = ' ';
        if (attributes) {
            attributes.forEach((attribute: { key: string; value: string }) => {
                html += `${attribute.key}="${attribute.value}" `;
            });
        }
        return html.trimEnd();
    }

    public static addTagClasses(...classesList: string[]): string {
        let html = '';
        if (classesList) {
            const classes = classesList.filter((className: string) => {
                return className.length > 0;
            });
            if (classes.length > 0) {
                html += ` class="${classes.join(' ').trim()}"`;
            }
        }
        return html;
    }

    public static addTagStyle(...styles: Array<{ key: string; value: string }>): string {
        let html = ' ';
        if (styles && styles.length > 0) {
            html += 'style="';
            styles.forEach((style: { key: string; value: string }) => {
                html += `${style.key}: ${style.value}; `;
            });
            html = html.trimEnd();
            html += '"';
        }
        return html;
    }

    public static closeTag(tag: string): string {
        return `</${tag}>\n`;
    }

    /**
     * @param columns
     */
    protected static sumColumnsMinWidth(columns: TableColumn[]): number {
        /**
         * @param sum includes sum all minWidth
         * @param col is FxTable column
         */
        const sumMinWidth = (sum: number, col: TableColumn): number => {
            const minWidth = col.minWidth ? col.minWidth : 0;
            return sum + minWidth;
        };

        const columnMinimumWidthSum: number = columns.reduce(sumMinWidth, 0);
        return columnMinimumWidthSum;
    }

    /**
     * @param component
     */
    protected static setColumnsWidthOfHeadersInPercent(component: FxTable): void {
        const tableModel: TableModel = component.tableModel;
        const reductionPercentage: number = FxTableUtils.calculateReductionPercentage(component);

        const visibility = component.renderer.table.style.visibility;
        const position = component.renderer.table.style.position;

        component.renderer.table.style.visibility = 'hidden';
        component.renderer.table.style.position = 'absolute';

        component.renderer.table
            .querySelectorAll<HTMLTableCellElement>('tr.header > td:not(.nested), tr.header > th')
            .forEach((cell: HTMLTableCellElement) => {
                const col = Number(cell.getAttribute('data-col-idx'));
                const pxWidth = tableModel.columns[col].width;
                const pxMinWidth = tableModel.columns[col].minWidth;

                if (pxMinWidth) {
                    cell.style.width = `${pxMinWidth}px`;
                    cell.style.minWidth = `${pxMinWidth}px`;
                } else if (pxWidth) {
                    let percentageWidth = 0;
                    if (pxWidth > 1) {
                        percentageWidth = FxTableUtils.pxToPercent(
                            component.renderer.table.clientWidth,
                            pxWidth * reductionPercentage
                        );
                    }
                    cell.style.width = `${percentageWidth}%`;
                    if (percentageWidth === 0) {
                        cell.classList.add('invisible');
                    }
                } else {
                    cell.style.width = '0';
                    cell.classList.add('invisible');
                }
            });

        component.renderer.table.style.visibility = visibility;
        component.renderer.table.style.position = position;
    }

    protected static setColumnsWidthOfHeadersInPixels(component: FxTable): void {
        const tableModel = component.tableModel;
        const totalColumnsWidth = FxTableUtils.calculateTotalColumnsWidths(component);
        const headers = component.renderer.table.querySelectorAll('thead');
        headers.forEach((header: HTMLElement) => {
            header.querySelectorAll('th, td').forEach((element: Element) => {
                const cell = element as HTMLTableCellElement;
                const col = Number(cell.getAttribute('data-col-idx'));
                const pxWidth = tableModel.columns[col].width;
                if (pxWidth) {
                    cell.style.width = `${pxWidth}px`;
                }

                const minPxWidth = tableModel.columns[col].minWidth;
                if (minPxWidth) {
                    cell.style.minWidth = `${minPxWidth}px`;
                }
            });
        });
        component.renderer.table.style.width = `${totalColumnsWidth}px`;
    }

    private static _generateCells(tableModel: TableModel): TableCell[] {
        const cells: TableCell[] = [];

        tableModel.columns.forEach(() => {
            cells.push({
                type: TableCellType.Text
            });
        });

        return cells;
    }

    /**
     * @param fxTable
     */
    private static calculateReductionPercentage(fxTable: FxTable): number {
        const minimumWidthSum: number = FxTableUtils.sumColumnsMinWidth(fxTable.tableModel.columns);
        const totalColumnsWidth: number = FxTableUtils.calculateTotalColumnsWidths(fxTable);
        const tableWidth: number = fxTable.renderer.table.clientWidth;
        const reductionPercentage: number =
            (tableWidth - minimumWidthSum) / (totalColumnsWidth - minimumWidthSum);
        if (reductionPercentage < 0) {
            return 0;
        }

        return reductionPercentage;
    }

    /**
     * @param component
     * @param rowIndex
     * @param colIndex
     * @param hide
     */
    private static _getColSpan(
        component: FxTable,
        rowIndex: number,
        colIndex: number,
        hide?: boolean
    ): number {
        const startIndex = colIndex;

        while (
            component.tableModel.rows[rowIndex].cells[colIndex].selected ||
            component.tableModel.rows[rowIndex].cells[colIndex].hide
        ) {
            if (
                (component.tableModel.rows[rowIndex].cells[colIndex].selected ||
                    component.tableModel.rows[rowIndex].cells[colIndex].hide) &&
                hide
            ) {
                component.tableModel.rows[rowIndex].cells[colIndex].hide = true;
            }

            colIndex++;
            if (colIndex === component.tableModel.rows[rowIndex].cells.length) {
                break;
            }
        }

        return colIndex - startIndex;
    }

    private static _getRowSpan(
        component: FxTable,
        startIndex: number,
        colIndex: number,
        colSpan: number
    ): number {
        let rowSpan = 0;

        for (let rowIndex = startIndex; rowIndex < component.tableModel.rows.length; rowIndex++) {
            if (
                !component.tableModel.rows[rowIndex].cells[colIndex].selected ||
                FxTableUtils._getColSpan(component, rowIndex, colIndex) !== colSpan
            ) {
                break;
            } else {
                FxTableUtils._getColSpan(component, rowIndex, colIndex, true);
            }
            rowSpan++;
        }

        return rowSpan;
    }

    private static _removeSpans(
        tableModel: TableModel,
        cell: TableCell,
        rowIndex: number,
        colIndex: number
    ): void {
        const rowSpan = cell.rowSpan || 1;
        const colSpan = cell.colSpan || 1;
        cell.rowSpan = undefined;
        cell.colSpan = undefined;

        for (let rowIdx = rowIndex; rowIdx <= rowIndex + rowSpan - 1; rowIdx++) {
            for (let colIdx = colIndex; colIdx <= colIndex + colSpan - 1; colIdx++) {
                tableModel.rows[rowIdx].cells[colIdx].hide = undefined;
                tableModel.rows[rowIdx].cells[colIdx].selected = true;
            }
        }
    }
}
