import { fxWebComponentEventFactory } from '@finantix/core';
import type { FxDropdown } from './fx-dropdown';

export class FxDropdownOpenEvent extends fxWebComponentEventFactory<{
    dropdown: FxDropdown
}>(
    'fx-dropdown:open',
    false
) { }

export class FxDropdownCloseEvent extends fxWebComponentEventFactory<{
    dropdown: FxDropdown
}>(
    'fx-dropdown:close',
    false
) { }
