import { FxSpinnerRenderer } from './fx-spinner.renderer';
import { FxElements, FxElement } from '@finantix/core';

@FxElements.register({
    selector: 'fx-spinner',
    renderer: new FxSpinnerRenderer()
})
export class FxSpinner extends FxElement {
    public update(attr: any, oldVal: any, newVal: any): boolean {
        return false;
    }
}
