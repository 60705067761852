import { FxElement, FxElements, FxProperties } from '@finantix/core';
import { FxMailViewerRenderer } from './fx-mail-viewer.renderer';
import i18n from './locales/locale.en.json';
import type {
    FxEmailAttachment,
    FxEmail,
    FxMailViewerOnAttachmentClickCallback
} from '../shared/models/fx-mail.model';

/**
 * FxMailViewer controller class
 *
 * @export
 * @class FxMailViewer
 * @extends {FxElement}
 */
@FxElements.register({
    selector: 'fx-mail-viewer',
    i18n,
    renderer: new FxMailViewerRenderer()
})
export class FxMailViewer extends FxElement {
    private _model: FxEmail | undefined;
    public onAttachmentClickCallback: FxMailViewerOnAttachmentClickCallback;

    @FxProperties.register({
        bind: '.mail-viewer'
    })
    public viewerElement: HTMLElement | undefined;

    @FxProperties.register()
    public dateFormat = 'MMM D, h:mm A';

    @FxProperties.register()
    public subjectLabel = 'Subject';

    @FxProperties.register()
    public fromLabel = 'From';

    @FxProperties.register()
    public toLabel = 'To';

    @FxProperties.register()
    public ccLabel = 'Cc';

    @FxProperties.register()
    public bccLabel = 'Bcc';

    @FxProperties.register()
    public dateSentLabel = 'Date sent';

    @FxProperties.register()
    public attachmentDownloadAllLabel = 'Download all attachments';

    @FxProperties.register()
    public attachmentNoticeTemplate = '<label>The attachments cannot be downloaded.</label>';

    @FxProperties.register()
    public attachmentInlineTemplate = undefined;

    @FxProperties.register({ type: Boolean })
    public attachmentDownload = true;

    /**
     * Creates an instance of FxMailViewer
     * @memberof FxMailViewer
     */
    public constructor() {
        super();
    }

    /**
     * Model getter
     *
     * @readonly
     * @type {FxEmail}
     * @memberof FxMailViewer
     */
    public get model(): FxEmail | undefined {
        return this._model;
    }

    /**
     * On attachment click set method
     *
     * @memberof FxMailViewer
     */
    public set onAttachmentClick(
        customMethod: (
            attachment: FxEmailAttachment | undefined,
            downloadAll: boolean,
            resolve: (isDownloadCompleted: boolean) => void
        ) => void
    ) {
        this.onAttachmentClickCallback = customMethod;
    }

    /**
     * Set data for webcomponent
     *
     * @param {FxEmail} model
     * @memberof FxMailViewer
     */
    public setData(model: FxEmail): void {
        this._model = model;
        this.refresh();
    }

    /**
     * Clear the model
     *
     * @memberof FxMailViewer
     */
    public clear(): void {
        this._model = undefined;
        this.refresh();
    }

    /**
     * Show loader
     *
     * @memberof FxMailViewer
     */
    public showLoading(): void {
        const spinner = this.viewerElement.getElementsByClassName('fx-spinner')[0] as HTMLElement;
        spinner.style.display = 'block';

        // Add no-overflow class from the scrollable container
        this.viewerElement.classList.add('no-scroll');
    }

    /**
     * The callback that is invoked from the external when the attachment is dowloaded
     *
     * @private
     * @param {FxEmailPreview[]} data
     * @memberof FxMailList
     */
    public onResolvedDownloadAttachment(isDownloadCompleted: boolean): void {
        // Hide the spinner at the end of the list
        const spinner = this.viewerElement.getElementsByClassName('fx-spinner')[0] as HTMLElement;
        spinner.style.display = 'none';

        // Remove no-overflow class from the scrollable container
        this.viewerElement.classList.remove('no-scroll');
    }
}
