import { FxElement, FxElements, FxHTMLElement } from '@finantix/core';

import { FxMultiList } from '../fx-multi-list';
import * as i18n from '../locales/locale.en.json';
import { FxMultiListItemRenderer } from './fx-multi-list-item.renderer';

import type { FxDropdown } from '../../fx-dropdown';

@FxElements.register({
    selector: 'fx-multi-list-item',
    i18n,
    renderer: new FxMultiListItemRenderer()
})
export class FxMultiListItem extends FxElement {
    public static get observedAttributes(): string[] {
        return [...FxElement.observedAttributes, 'marker', 'removable', 'locked', 'remove-label'];
    }

    // Configuration options
    private _marker: boolean;
    private _locked: boolean;
    private _removable: boolean;
    private _removeLabel: string;
    // Internal HTML references
    private _data: HTMLElement[];
    private _menu: FxDropdown | null;

    public constructor() {
        super();
    }

    public get showSpecialMarker(): boolean {
        return this._marker;
    }

    public get isLocked(): boolean {
        return this._locked;
    }

    public get isRemovable(): boolean {
        return this._removable;
    }

    public get removeText(): string {
        return this._removeLabel;
    }

    public get data(): HTMLElement[] {
        return this._data;
    }

    public get menu(): FxDropdown | null {
        return this._menu;
    }

    public get parent(): FxMultiList | null {
        return this.parentElement instanceof FxMultiList ? this.parentElement : null;
    }

    public get dataWrapper(): HTMLElement | null {
        return this.querySelector('.data-wrapper');
    }

    public get actions(): HTMLElement | null {
        return this.querySelector('.actions');
    }

    public get actionButton(): HTMLElement | null {
        return this.querySelector('.action-button');
    }

    public onConnected(): void {
        this._marker = this.getAttributeBool('marker', false);
        this._locked = this.getAttributeBool('locked', false);
        this._removable = this.getAttributeBool('removable', false);
        this._removeLabel = this.getAttributeString(
            'remove-label',
            this.translateContent('fx-multi-list.menu.remove.label')
        );

        // We want to extract HTML original structures only the first time the element is about
        // to be created, that's why we look at the "CREATED" phase that happens only the first time
        // the element is created. If the Element will be detached from the DOM and then re-attached
        // the currentPhase here will be "DISCONNECTED"
        if (this.currentPhase === 'CREATED') {
            this._data = this.extractData();
            this._menu = this.extractMenu();
        }

        // This call to the ancestor method will also set the proper phase to "CONNECTED", leave it
        // as the last one
        super.onConnected();
    }

    public onAttributeChanged(attr: any, oldVal: any, newVal: any): void {
        if (this.initialized && oldVal !== newVal) {
            switch (attr) {
                case 'marker':
                    this._marker = newVal === 'true';
                    break;
                case 'removable':
                    this._removable = newVal === 'true';
                    break;
                case 'locked':
                    this._locked = newVal === 'true';
                    break;
                case 'remove-label':
                    this._removeLabel = newVal;
                    break;
            }
        }
    }

    public shouldShowRemoveButton(): boolean {
        const parentAllowToDelete =
            !this.parent || this.parent.children.length > this.parent.minItems;
        return parentAllowToDelete && this.isRemovable;
    }

    private extractData(): HTMLElement[] {
        const selectors = 'fx-multi-list-item > *:not(fx-dropdown.multi-list-menu)';
        const data: NodeListOf<HTMLElement> = this.querySelectorAll(selectors);
        return Array.from(data).map((element) => FxHTMLElement.detachElement(element));
    }

    private extractMenu(): FxDropdown | null {
        const selectors = 'fx-dropdown.multi-list-menu';
        const menu: FxDropdown | null = this.querySelector(selectors);
        return menu ? FxHTMLElement.detachElement(menu) : null;
    }
}
