import { FxRenderer } from '@finantix/core';
import type { FxSpinner } from './fx-spinner';

export class FxSpinnerRenderer extends FxRenderer {
    public render(context: string, container: FxSpinner): void {
        container.innerHTML = '';

        const loader = document.createElement('div') as HTMLDivElement;
        loader.className = 'spinner-container';
        const spinner = document.createElement('div') as HTMLDivElement;
        spinner.className = 'spinner';
        loader.appendChild(spinner);
        container.appendChild(loader);
    }
}
