import TomSelect from 'tom-select/dist/esm/tom-select';
import 'tom-select/dist/esm/plugins/checkbox_options/plugin';
import { addEvent } from 'tom-select/dist/esm/utils';

//This plugin is used to fix the accessibility of select, such as the navigation within the window, navigation throw the elements of the dropdown and how to open the dropdown menu

TomSelect.define('select_accessibility', function (this: TomSelect) {
    setTimeout(() => {
        addEvent(this.wrapper, 'keydown', (e) => { onKeyDownEvent(e as KeyboardEvent, this); e.stopPropagation() });
        if (this.control_input.parentElement.style.display === 'none') {
            addEvent(this.wrapper, 'keydown', (e) => { this.onKeyDown(e as KeyboardEvent); e.stopPropagation() });
            addEvent(this.wrapper, 'keyup', (e) => { this.onKeyUp(e as KeyboardEvent); e.stopPropagation() });
            addEvent(this.wrapper, 'blur', () => this.onBlur());
            addEvent(this.wrapper, 'keypress', (e) => { this.onKeyPress(e as KeyboardEvent); e.stopPropagation() });
        }
    });

    function onKeyDownEvent(e: KeyboardEvent, ts: TomSelect) {
        switch (e.code) {
            case 'Space':
            case 'ArrowDown':
                if (!ts.isOpen) {
                    ts.open();
                    if (ts.settings.mode === 'single') {
                        ts.setActiveOption(ts.dropdown_content.querySelector('.selected'));
                    }
                    e.preventDefault();
                }
                return;
            case 'Backspace':
                if (!ts.isOpen) {
                    ts.clear();
                    ts.deleteSelection();
                    ts.control.querySelector('.multi-select-text').innerHTML = '';
                }
                return;
            case 'Tab':
                if (!e.shiftKey) {
                    goToNextFocusableNode(e, ts);
                } else {
                    goToPerviousFocusableNode(e, ts);
                }
                return;
        }
    }

    function goToNextFocusableNode(e: KeyboardEvent, ts: TomSelect) {
        if (ts.settings.mode != 'single') {
            e.preventDefault();
            let parent = document.body;
            let focusableList = getFocusableNodeListIn(parent, true);
            var node = focusableList.nextNode() as HTMLElement;

            while (node != ts.wrapper) {
                node = focusableList.nextNode() as HTMLElement;
            }
            node = focusableList.nextNode() as HTMLElement;

            while (isDescendant(ts.wrapper, node)) {
                node = focusableList.nextNode() as HTMLElement;
            }
            if (node) {
                node.focus();
            }
        }
    }

    function goToPerviousFocusableNode(e: KeyboardEvent, ts: TomSelect) {
        if (ts.control_input.style.display != 'none') {
            e.preventDefault();
            let parent = document.body;
            let focusableList = getFocusableNodeListIn(parent, true);
            var node = focusableList.nextNode() as HTMLElement;

            while (node != ts.wrapper) {
                node = focusableList.nextNode() as HTMLElement;
            }
            node = focusableList.previousNode() as HTMLElement;
            let computedStyle = window.getComputedStyle(node);
            let nodeVisibility = computedStyle.visibility;

            while (isDescendant(node, ts.wrapper) || nodeVisibility === 'hidden') {
                node = focusableList.previousNode() as HTMLElement;
                computedStyle = window.getComputedStyle(node);
                nodeVisibility = computedStyle.visibility;
            }

            if (node) {
                node.focus();
            }
        }
    }

    function isDescendant(parent, child) {
        if (child) {
            var node = child.parentNode;
            while (node != null) {
                if (node == parent) {
                    return true;
                }
                node = node.parentNode;
            }
            return false;
        }
    }

    function getFocusableNodeListIn(
        root: HTMLElement,
        moveToFirst: boolean = true
    ): TreeWalker {
        const tw: TreeWalker = document.createTreeWalker(
            root,
            NodeFilter.SHOW_ELEMENT,
            {
                acceptNode: (
                    node: HTMLElement
                ) => node.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP
            }
        );

        if (moveToFirst) {
            tw.nextNode();
        }
        return tw;
    }

    this.hook('after', 'onKeyDown', (e: KeyboardEvent) => {
        switch (e.code) {
            case 'Escape':
                this.wrapper.focus();
                break;
            case 'ArrowDown':
                if (this.isOpen) {
                    if (!this.activeOption) {
                        this.setActiveOption(this.dropdown_content.querySelector('.option'));
                    }
                }
                break;
        }
    });

    this.hook('after', 'refreshOptions', () => {
        if (this.items.length === 0) {
            this.clearActiveOption();
        }
    });

    const hash_key = value => {
        if (typeof value === 'undefined' || value === null) return null;
        return get_hash(value);
    };
    const get_hash = value => {
        if (typeof value === 'boolean') return value ? '1' : '0';
        return value + '';
    };
    const nodeIndex = (el, amongst) => {
        if (!el) return -1;
        amongst = amongst || el.nodeName;
        var i = 0;

        while (el = el.previousElementSibling) {
            if (el.matches(amongst)) {
                i++;
            }
        }

        return i;
    };


    this.hook('instead', 'removeItem', (item) => {
        const self = this;
        if (item === "") {
            var key = hash_key(item);
            item = this.control.querySelector(`[data-value="${(key)}"]`);
        } else {
            item = self.getItem(item);
        }
        if (!item) return;
        var i, idx;
        const value = item.dataset.value;
        i = nodeIndex(item, null);
        item.remove();

        if (item.classList.contains('active')) {
            idx = self.activeItems.indexOf(item);
            self.activeItems.splice(idx, 1);
            self.removeClasses(item, 'active');
        }

        self.items.splice(i, 1);
        self.lastQuery = null;

        if (!self.settings.persist && self.userOptions.hasOwnProperty(value)) {
            self.removeOption(value, null);
        }

        if (i < self.caretPos) {
            self.setCaret(self.caretPos - 1);
        }

        self.updateOriginalInput({
            silent: null
        });
        self.refreshState();
        self.trigger('item_remove', value, item);
    });
});