import 'tom-select/dist/esm/plugins/clear_button/plugin';
import 'tom-select/dist/esm/plugins/dropdown_input/plugin';
import 'tom-select/dist/esm/plugins/no_backspace_delete/plugin';
import 'tom-select/dist/esm/plugins/remove_button/plugin';
import './plugins/checkbox-options';
import './plugins/clear-button-multiple';
import './plugins/clear-button-single';
import './plugins/popper-fix';
import './plugins/select-accessibility';
import './plugins/virtual-scroll';

import TomSelect from 'tom-select/dist/esm/tom-select';

import { FxRenderer } from '@finantix/core';
import { createPopper } from '@popperjs/core';

import { FxSelectDropdownCloseEvent, FxSelectDropdownOpenEvent } from './fx-select.events';
import { parseLibraryValue } from './fx-select.utils';

import type { FxSelect } from './fx-select';

/**
 * Class responsible to render the `fx-select`
 *
 * @class FxSelectRenderer
 * @extends {FxRenderer}
 */
export class FxSelectRenderer extends FxRenderer {
    /**
     * Creates an instance of FxSelectRenderer.
     *
     * @memberof FxSelectRenderer
     */
    public constructor() {
        super();
    }

    /**
     * Principal rendering entrypoint.
     *
     * @param {string} context the context in which the `fx-select` is going to be rendered
     * @param {FxSelect} fxSelect the `fx-select` class which contains all properties
     * @memberof FxSelectRenderer
     */
    public render(context: string, fxSelect: FxSelect): void {
        const select: HTMLSelectElement = this.getHtmlSelect(fxSelect);
        this.initTomSelect(fxSelect, select);
        this.addOpenCloseIcon(fxSelect);
        this.addPlaceholder(fxSelect);
        this.toggleSearchInput(fxSelect);
        this.togglePlaceholder(fxSelect);
    }

    /**
     * Create a standard HTML select that will be used by TomSelect library to instantiate itself
     *
     * @private
     * @param {FxSelect} fxSelect the `fx-select` class which contains all properties
     * @returns {*} `HTMLSelectElement`
     * @memberof FxSelectRenderer
     */
    private getHtmlSelect(fxSelect: FxSelect): HTMLSelectElement {
        if (fxSelect._select) {
            fxSelect.reset();
        } else {
            fxSelect._select = document.createElement('select');
            fxSelect.querySelectorAll('fx-select > option').forEach((option: HTMLOptionElement) => {
                fxSelect._select.appendChild(option);
            });
            fxSelect.appendChild(fxSelect._select);
        }

        fxSelect._select.multiple = fxSelect.multi;
        fxSelect._select.disabled = fxSelect.disabled;
        fxSelect._select.setAttribute(
            'placeholder',
            fxSelect.translateContent(fxSelect.placeholderSearch)
        );

        return fxSelect._select;
    }

    /**
     * Create a callback function that can be attached to the hook when the value is changed from
     * the library (either a user interaction or some programmatic call to the `setValue` method)
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @returns {*} `(value: string | string[]) => void`
     * @memberof FxSelectRenderer
     */
    private onChange(fxSelect: FxSelect): (value: string | string[]) => void {
        return (value: string | string[]): void => {
            const newValue = parseLibraryValue(value);
            const currentValue = fxSelect.value
                .split(',')
                .map((token) => token.trim())
                .join(',');
            if (currentValue !== newValue) {
                fxSelect.disableRefreshOnAttributeChanged();
                fxSelect.value = newValue;
                fxSelect.enableRefreshOnAttributeChanged();

                this.togglePlaceholder(fxSelect);
            }
        };
    }

    /**
     * Create a callback function that can be attached to the hook when the dropdown is opened
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @returns {*} `() => void`
     * @memberof FxSelectRenderer
     */
    private onDropdownOpen(fxSelect: FxSelect): () => void {
        return (): void => {
            fxSelect.dispatchEvent(
                new FxSelectDropdownOpenEvent({
                    dropdown: fxSelect._ts.dropdown
                })
            );

            if (fxSelect._popper) {
                fxSelect._popper.update();
            }

            const icon: HTMLElement = fxSelect.querySelector('.dropdown-arrow');
            if (icon) {
                icon.classList.replace(fxSelect.dropdownIconClose, fxSelect.dropdownIconOpen);
            }
        };
    }

    /**
     * Create a callback function that can be attached to the hook when the dropdown is opened
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @returns {*} `() => void`
     * @memberof FxSelectRenderer
     */
    private onDropdownClose(fxSelect: FxSelect): () => void {
        return (): void => {
            fxSelect.dispatchEvent(
                new FxSelectDropdownCloseEvent({
                    dropdown: fxSelect._ts.dropdown
                })
            );

            const icon: HTMLElement = fxSelect.querySelector('.dropdown-arrow');
            if (icon) {
                icon.classList.replace(fxSelect.dropdownIconOpen, fxSelect.dropdownIconClose);
            }
        };
    }

    /**
     * Return a string that represent the container of the dropdown
     *
     * @private
     * @returns {*} `string`
     * @memberof FxSelectRenderer
     */
    private renderDropdown(): () => string {
        return () => /* html */ `
            <div class="fx-select-dropdown"></div>
        `;
    }

    /**
     * Return a string that represent the container of the dropdown
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @returns {*} `string`
     * @memberof FxSelectRenderer
     */
    private renderNoResults(fxSelect: FxSelect): () => string {
        return () => /* html */ `
            <div class="no-results">
                <span>
                    ${fxSelect.translateContent(fxSelect.placeholderNoResult)}
                </span>
            </div>
        `;
    }

    /**
     * Retrieve the base TomSelect config
     *
     * **IMPORTANT**
     *
     * *This function does not return a type because the 3rd party library still not
     * provide it (as of 1.7.7). As soon as we will update to version 2 or higher all typings
     * should be available*
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @returns {*}  {*}
     * @memberof FxSelectRenderer
     */
    private getBaseConfig(fxSelect: FxSelect): any {
        return {
            allowEmptyOption: true,
            hideSelected: false,
            hidePlaceholder: true,
            maxOptions: fxSelect.maxOptions || null,
            items: fxSelect.value ? fxSelect.value.split(',') : [],
            valueField: fxSelect.valueField || 'value',
            labelField: fxSelect.labelField || 'text',
            searchField: fxSelect.searchField ? fxSelect.searchField.split(',') : ['text'],
            // Events
            onChange: this.onChange(fxSelect),
            onDropdownOpen: this.onDropdownOpen(fxSelect),
            onDropdownClose: this.onDropdownClose(fxSelect),
            plugins: {
                popper_fix: {}
            },
            render: {
                dropdown: this.renderDropdown()
            }
        };
    }

    /**
     * Enhance the given config with settings needed to display the search feature
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @param {*} config the config to extend
     * @memberof FxSelectRenderer
     */
    private addSearch(fxSelect: FxSelect, config: any): void {
        config.plugins = {
            ...config.plugins,

            dropdown_input: {}
        };

        config.render.no_results = this.renderNoResults(fxSelect);
    }

    /**
     * Enhance the given config with settings needed to display the clear button
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @param {*} config the config to extend
     * @memberof FxSelectRenderer
     */
    private addClearButton(fxSelect: FxSelect, config: any): void {
        config.plugins = {
            ...config.plugins,

            clear_button_single: {}
        };
    }

    /**
     * Enhance the given config with settings needed to display checkboxes (in case of multi)
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @param {*} config the config to extend
     * @memberof FxSelectRenderer
     */
    private addCheckboxOptions(fxSelect: FxSelect, config: any): void {
        config.plugins = {
            ...config.plugins,

            checkbox: {},
            remove_button: {}
        };
    }

    /**
     * Compute the final TomSelect configuration
     *
     * **IMPORTANT**
     *
     * *This function does not return a type because the 3rd party library still not
     * provide it (as of 1.7.7). As soon as we will update to version 2 or higher all typings
     * should be available*
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @returns {*}  {*}
     * @memberof FxSelectRenderer
     */
    private getTomSelectConfig(fxSelect: FxSelect): any {
        const config = this.getBaseConfig(fxSelect);
        if (fxSelect.searchable) {
            this.addSearch(fxSelect, config);
        }
        if (fxSelect.multi) {
            this.addCheckboxOptions(fxSelect, config);
        }
        if (fxSelect.clearable) {
            this.addClearButton(fxSelect, config);
        }

        return config;
    }

    /**
     * Initialize TomSelect library
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @param {HTMLSelectElement} select the select used by TomSelect to initialize itself
     * @memberof FxSelectRenderer
     */
    private initTomSelect(fxSelect: FxSelect, select: HTMLSelectElement): void {
        if (!fxSelect._ts) {
            const config = this.getTomSelectConfig(fxSelect);
            fxSelect._ts = new TomSelect(select, config);
            fxSelect._popper = createPopper(fxSelect._ts.control, fxSelect._ts.dropdown, {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8]
                        }
                    }
                ]
            });

            fxSelect._resizeObserver.observe(fxSelect._ts.dropdown);
        }
    }

    /**
     * Add and open/close icon to the select
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @memberof FxSelectRenderer
     */
    private addOpenCloseIcon(fxSelect: FxSelect): void {
        const icon: HTMLElement = document.createElement('i');
        icon.className = 'mdi';
        icon.classList.add(fxSelect.dropdownIconClose);
        icon.classList.add('dropdown-arrow');
        icon.setAttribute('aria-hidden', 'true');
        if (!fxSelect.multi) {
            const singleClass = fxSelect.querySelector<HTMLElement>('.single');
            if (singleClass) {
                singleClass.classList.remove('single');
            }
        }

        fxSelect._ts.control.appendChild(icon);
    }

    /**
     * Add a placeholder text to the select
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @memberof FxSelectRenderer
     */
    private addPlaceholder(fxSelect: FxSelect): void {
        fxSelect._placeholderHml = document.createElement('span');
        fxSelect._placeholderHml.innerText = fxSelect.translateContent(fxSelect.placeholder);
        fxSelect._placeholderHml.classList.add('placeholder');
        fxSelect._ts.control.appendChild(fxSelect._placeholderHml);
    }

    /**
     * Show/hide the search input based on `fx-select` configuration
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @memberof FxSelectRenderer
     */
    private toggleSearchInput(fxSelect: FxSelect): void {
        if (fxSelect._ts && !fxSelect.searchable) {
            const searchInput: HTMLElement = fxSelect._ts.control_input;
            searchInput.style.setProperty('visibility', 'hidden');
            searchInput.style.setProperty('display', 'none', 'important');
        }
    }

    /**
     * Show/hide the placeholder based on `fx-select` selected values
     *
     * @private
     * @param {FxSelect} fxSelect the current Element
     * @memberof FxSelectRenderer
     */
    private togglePlaceholder(fxSelect: FxSelect): void {
        if (fxSelect.value && fxSelect.value.length > 0) {
            fxSelect._placeholderHml?.style.setProperty('visibility', 'hidden');
            fxSelect._placeholderHml?.style.setProperty('display', 'none');
        } else {
            fxSelect._placeholderHml?.style.removeProperty('visibility');
            fxSelect._placeholderHml?.style.removeProperty('display');
        }
    }
}
