import { FxElement, FxElements, FxProperties } from '@finantix/core';
import { FxMailListRenderer } from './fx-mail-list.renderer';
import i18n from './locales/locale.en.json';
import type { FxEmailPreview, FxMailListLoadCallback } from '../shared/models/fx-mail.model';

/**
 * FxMailList controller class
 *
 * @export
 * @class FxMailList
 * @extends {FxElement}
 */
@FxElements.register({
    selector: 'fx-mail-list',
    i18n,
    renderer: new FxMailListRenderer()
})
export class FxMailList extends FxElement {
    private _scrollAnimationTick = null;
    private _hasBreachedThreshold = false;
    private _offset = 0;
    private _loadDataCallback: FxMailListLoadCallback;
    private _isLoadingData = false;
    private _stopLoadingData = false;
    private _model: FxEmailPreview[] = [];
    private _addedModel: FxEmailPreview[] = [];
    private _setDataModelCounter = 0;
    private _selectedItems: FxEmailPreview[] = [];
    public onItemClickCallback;
    public onLinkClickCallback;
    public onCheckboxClickCallback;
    public onViewClickCallback;

    @FxProperties.register()
    public viewMode;

    @FxProperties.register({ type: Number })
    public pageLength = 10;

    @FxProperties.register({ type: Number })
    public thresholdLimit = 0.85;

    @FxProperties.register()
    public dateFormat = 'MMM D, h:mm A';

    @FxProperties.register()
    public noDataLabel = 'No data available.';

    @FxProperties.register()
    public noSubjectLabel = '(No Subject)';

    @FxProperties.register({ type: Boolean })
    public multipleSelection = false;

    @FxProperties.register()
    public markerTitle = '';

    @FxProperties.register()
    public weblinkTitle = '';

    @FxProperties.register({ type: Boolean })
    public activation = false;

    @FxProperties.register({ type: Boolean })
    public viewAction = true;

    @FxProperties.register()
    public viewButtonClass = 'mdi-eye';

    @FxProperties.register()
    public viewTitle = '';

    @FxProperties.register({
        bind: '.mail-list'
    })
    public listElement: HTMLElement | undefined;

    /**
     * Creates an instance of FxMailList
     *
     * @memberof FxMailList
     */
    public constructor() {
        super();
    }

    /**
     * model getter method
     *
     * @type {(FxEmailPreview[] | undefined)}
     * @memberof FxMailList
     */
    public get model(): FxEmailPreview[] | undefined {
        return this._model;
    }

    /**
     * addedModel getter method
     *
     * @type {(FxEmailPreview[] | undefined)}
     * @memberof FxMailList
     */
    public get addedModel(): FxEmailPreview[] | undefined {
        return this._addedModel;
    }

    /**
     * setDataModelCounter getter method
     *
     * @type {number}
     * @memberof FxMailList
     */
    public get setDataModelCounter(): number {
        return this._setDataModelCounter;
    }

    /**
     * selectedItems getter method
     *
     * @type {(FxEmailPreview[])}
     * @memberof FxMailList
     */
    public get selectedItems(): FxEmailPreview[] {
        return this._selectedItems;
    }

    /**
     * model setter method
     *
     * @memberof FxMailList
     */
    public set model(newValue) {
        this._model = newValue;
    }

    /**
     * addedModel setter method
     *
     * @memberof FxMailList
     */
    public set addedModel(newValue) {
        this._addedModel = newValue;
    }

    /**
     * setDataModelCounter setter method
     *
     * @memberof FxMailList
     */
    public set setDataModelCounter(newValue) {
        this._setDataModelCounter = newValue;
    }

    /**
     * selectedItems setter method
     *
     * @memberof FxMailList
     */
    public set selectedItems(newValue) {
        this._selectedItems = newValue;
    }

    /**
     * loadData method setter
     *
     * @memberof FxMailList
     */
    public set loadData(
        customMethod: (
            offset: number,
            count: number,
            resolve: (data: FxEmailPreview[]) => void
        ) => void
    ) {
        this._loadDataCallback = customMethod;
    }

    /**
     * onItemClick method setter
     *
     * @memberof FxMailList
     */
    public set onItemClick(
        customMethod: (item: FxEmailPreview, resolve: (status: boolean) => void) => void
    ) {
        this.onItemClickCallback = customMethod;
    }

    /**
     * onLinkClick method setter
     *
     * @memberof FxMailList
     */
    public set onLinkClick(
        customMethod: (item: FxEmailPreview, resolve: (status: boolean) => void) => void
    ) {
        this.onLinkClickCallback = customMethod;
    }

    /**
     * onSelectionClick method setter
     *
     * @memberof FxMailList
     */
    public set onCheckboxClick(
        customMethod: (items: FxEmailPreview[], resolve: (status: boolean) => void) => void
    ) {
        this.onCheckboxClickCallback = customMethod;
    }

    /**
     * onViewClick method setter
     *
     * @memberof FxMailList
     */
    public set onViewClick(
        customMethod: (item: FxEmailPreview, resolve: (status: boolean) => void) => void
    ) {
        this.onViewClickCallback = customMethod;
    }

    /**
     * On after rendererd callback
     *
     * @param {string} context
     * @memberof FxMailList
     */
    public override onAfterRendered(context: string): void {
        super.onAfterRendered(context);
        if (this.listElement) {
            this.listElement.addEventListener('scroll', this.scrollHandler.bind(this), {
                passive: true
            });
        }
    }

    /**
     * Add the scroll listener to the list and invoke the external definition of loadData method
     *
     * @return {*}  {void}
     * @memberof FxMailList
     */
    public scrollHandler(): void {
        if (this._scrollAnimationTick) {
            return;
        }

        this._scrollAnimationTick = window.requestAnimationFrame(() => {
            const currentThreshold =
                (this.listElement.scrollTop + this.listElement.clientHeight) /
                this.listElement.scrollHeight;

            if (currentThreshold >= this.thresholdLimit) {
                if (!this._hasBreachedThreshold && !this._isLoadingData && !this._stopLoadingData) {
                    this._showSpinner();

                    this._hasBreachedThreshold = true;
                    this._isLoadingData = true;

                    this._loadDataCallback(
                        this._offset,
                        this.pageLength,
                        this.onResolvedData.bind(this)
                    );
                }
            } else {
                this._hasBreachedThreshold = false;
            }
            this._scrollAnimationTick = null;
        });
    }

    /**
     * Default method called when the Element is disconnected from the DOM
     *
     * @memberof FxMailList
     */
    public override onDisconnected(): void {
        if (this.listElement) {
            this.listElement.removeEventListener('scroll', this.scrollHandler.bind(this));
        }
        cancelAnimationFrame(this._scrollAnimationTick);
        this._scrollAnimationTick = null;

        super.onDisconnected();
    }

    /**
     * Clear the model showing the spinner
     *
     * @memberof FxMailList
     */
    public clear(): void {
        this._model = [];
        this._addedModel = [];
        this._selectedItems = [];
        this._setDataModelCounter = 0;
        this._isLoadingData = false;
        this._hasBreachedThreshold = false;
        this._offset = 0;
        this._stopLoadingData = false;
        this.refresh();
    }

    /**
     * Fetch the data
     *
     * @memberof FxMailList
     */
    public fetch(): void {
        this._showSpinner();
        this._isLoadingData = true;
        this._loadDataCallback(0, this.pageLength, this.onResolvedData.bind(this));
    }

    /**
     * The callback that is invoked from the external when the data is dowloaded
     *
     * @private
     * @param {FxEmailPreview[]} data
     * @memberof FxMailList
     */
    private onResolvedData(data: FxEmailPreview[]): void {
        this._hideSpinner();
        this._isLoadingData = false;
        this._setDataModel(data);
        // Reached the end of items available from BE
        if (data.length < this.pageLength) {
            this._stopLoadingData = true;
        }
    }

    /**
     * Handle the model property and force a new render
     *
     * @private
     * @param {FxEmailPreview[]} model
     * @memberof FxMailList
     */
    private _setDataModel(data: FxEmailPreview[]): void {
        this._setDataModelCounter++;
        if (this._setDataModelCounter < 2) {
            // The first time
            this._model = data;
            this.refresh();
        } else {
            this._addedModel = data;
            this.refresh();
            // Update model and reset added entries as further step (pagination or infinite scroll)
            this._model = [...this._model, ...this._addedModel];
            this._addedModel = [];
        }
        this._offset += this.pageLength;
    }

    /**
     * Hide the spinner at the end of the list
     *
     * @private
     * @memberof FxMailList
     */
    private _hideSpinner(): void {
        const spinner = this.listElement.getElementsByClassName(
            'mail-item spinner'
        )[0] as HTMLElement;
        spinner.style.display = 'none';
    }

    /**
     * Show the spinner at the end of the list
     *
     * @private
     * @memberof FxMailList
     */
    private _showSpinner(): void {
        const spinner = this.listElement.getElementsByClassName(
            'mail-item spinner'
        )[0] as HTMLElement;
        spinner.style.display = 'block';
    }
}
