import { FxRenderer } from '@finantix/core';
import type { FxMultiList } from './fx-multi-list';
import type { FxMultiListItem } from './fx-multi-list-item';
import { FxMultiListItemMovedEvent } from './fx-multi-list.events';
import Sortable from 'sortablejs'
export class FxMultiListRenderer extends FxRenderer {

    constructor() {
        super();
    }

    public render(context: string, list: FxMultiList): void {
        if (list.currentPhase === "FIRST_PAINTING") {
            if (list.isSortable) {
                Sortable.create(list, {
                    handle: `.${ list.DRAG_HANDLE_CLASS }`,
                    onEnd: (event) => {
                        list.dispatchEvent(new FxMultiListItemMovedEvent({
                            item: event.item as FxMultiListItem,
                            newIndex: event.newIndex || -1,
                            keysSorted: list.itemsKeys
                        }));
                    }
                });
            }
        }
    }

}

