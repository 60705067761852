import TomSelect from 'tom-select/dist/esm/tom-select';

const addClasses = (elements, ...classes) => {
    var norm_classes = classesArray(classes);
    elements = castAsArray(elements);
    elements.map(el => {
        norm_classes.map(cls => {
            el.classList.add(cls);
        });
    });
};

const classesArray = args => {
    var classes: Array<any> = [];

    for (let _classes of args) {
        if (typeof _classes === 'string') {
            _classes = _classes.trim().split(/[\11\12\14\15\40]/);
        }

        if (Array.isArray(_classes)) {
            classes = classes.concat(_classes);
        }
    }
    return classes.filter(Boolean);
};

const castAsArray = arg => {
    if (!Array.isArray(arg)) {
        arg = [arg];
    }
    return arg;
};

TomSelect.define('virtual_scroll', function (this: TomSelect) {
    const self = this;
    const orig_canLoad = self.canLoad;
    const orig_clearActiveOption = self.clearActiveOption;
    const orig_loadCallback = self.loadCallback;
    var pagination = {};
    var dropdown_content;
    var loading_more: boolean = false;

    let doFetch = this.plugins.settings.virtual_scroll.load;
    let retrievedData: any;
    let nextUrl: string;

    if (!self.settings.firstUrl) {
        throw new Error('virtual_scroll plugin requires a firstUrl() method');
    }

    self.settings.sortField = [{
        field: '$order'
    }, {
        field: '$score'
    }];

    self.on('initialize', () => {
        doFetch('', this.loadCallback, returnData);
        dropdown_content = self.dropdown_content;

        dropdown_content.addEventListener('scroll', function () {
            if (dropdown_content.children.length > 1) {
                const scroll_percent = dropdown_content.clientHeight / (dropdown_content.scrollHeight - dropdown_content.scrollTop);
                if (!self.activeOption){
                    self.activeOption = self.dropdown_content.children[0];
                }
                if (scroll_percent < 0.95) {
                    return;
                }
                if (!canLoadMore(self.lastValue)) {
                    return;
                }
                if (loading_more) return;

                loading_more = true;
                self.load.call(self, self.lastValue);
            }
        });
    });

    function canLoadMore(query) {
        if (typeof self.settings.maxOptions === 'number' && dropdown_content.children.length >= self.settings.maxOptions) {
            return false;
        }

        if (query in pagination && pagination[query]) {
            return true;
        }
        return false;
    }

    self.setNextUrl = function (value, next_url) {
        if (nextUrl === "") {
            delete pagination[value];
        } else {
            pagination[value] = next_url;
        }
    };

    self.getUrl = function (query) {
        if (query in pagination) {
            const next_url = pagination[query];
            return next_url;
        }
        if (query != "") {
            delete pagination[query];
        }
        return self.settings.firstUrl(query);
    };

    self.hook('instead', 'clearActiveOption', () => {
        if (loading_more) {
            return;
        }
        return orig_clearActiveOption.call(self);
    });

    self.hook('instead', 'canLoad', query => {
        // first time the query has been seen
        if (!(query in pagination)) {
            return orig_canLoad.call(self, query);
        }
        return canLoadMore(query);
    });

    self.hook('instead', 'loadCallback', (options, optGroups) => {
        if (!loading_more) {
            self.clearOptions();
        }

        orig_loadCallback.call(self, options, optGroups);
        loading_more = false;
    });

    self.hook('after', 'refreshOptions', () => {
        const query = self.lastValue;
        var option: any;

        if (canLoadMore(query) && !dropdown_content.querySelector('.no-results')) {
            option = self.render('loading_more', {
                query: query
            });
            self.loading = 0;
            if (dropdown_content.querySelector('.loading'))
                dropdown_content.querySelector('.loading').remove();
            if (option) {
                option.setAttribute('data-selectable', '');
            }
        } else if (query in pagination && !dropdown_content.querySelector('.no-results')) {
            option = self.render('no_more_results', {
                query: query
            });
        }
        if (self.loading) {
            this.clearOptions();
        }

        if (option) {
            addClasses(option, self.settings.optionClass);
            dropdown_content.append(option);
        }
    });

    this.hook('after', 'onKeyDown', (e: KeyboardEvent) => {
        if (e.code >= 'KeyA' && e.code <= 'KeyZ') {
            if (this.control_input.value != "") {
                delete pagination[this.control_input.value];
            }
        }
        if (e.code === 'Backspace') {
            const query = this.control_input.value;
            let selectedItems: HTMLElement = this.control.querySelector('.multi-select-text');
            let hasText: boolean = false;
            if (selectedItems) {
                hasText = selectedItems.innerText != "" ? true : false;
            }
            if (query.length <= 1 && !hasText) {
                if (retrievedData) {
                    this.clear();
                    this.clearOptions();
                    retrievedData.forEach(element => {
                        this.addOption(element);
                        this.refreshOptions();
                    });
                }
            }
            if (this.control_input.value != "") {
                delete pagination[this.control_input.value];
            }
        }
        if (e.code === 'ArrowDown') {
            let spinner = this.dropdown_content.querySelector('.loading-more-results');
            if (this.activeOption === spinner) {
                this.setActiveOption(spinner.previousSibling);
            }
        }
    });

    this.hook('after', 'open', () => {
        const query = this.lastQuery;
        let selectedItemsContainer: HTMLElement = this.control.querySelector('.multi-select-text');
        let hasText: boolean = false;

        if (selectedItemsContainer) {
            hasText = selectedItemsContainer.innerText != "" ? true : false;
        }
        if (!hasText) {
            if (retrievedData) {
                this.clearOptions();
                retrievedData.forEach(element => {
                    this.addOption(element);
                    this.refreshOptions(false);
                });
            }
        }

        updatePagination(hasText);
        moveSelectedItemsToTop(this);
    });

    function updatePagination(hasSelectedOptions: boolean) {
        for (const [key, value] of Object.entries(pagination)) {
            if (key != "") {
                delete pagination[key];
            }
            else {
                if (hasSelectedOptions) {
                    pagination[key] = self.getUrl();
                } else {
                    if (nextUrl) {
                        pagination[key] = nextUrl;
                    }
                }
            }
        }
    }

    function moveSelectedItemsToTop(ts: TomSelect) {
        if (ts.items.length !== 0) {
            ts.clearOptions();
            doFetch('', ts.loadCallback);
        }
    }

    function returnData(data: any, next_url: string) { // get the returned data from the research and the next url 
        retrievedData = data;
        nextUrl = next_url;
    }

    this.hook('after', 'onOptionSelect', () => {
        if (this.plugins.names.includes('clear_button')) {
            this.dropdown.style.visibility = 'hidden';
        }
    });

    this.hook('before', 'addItem', () => {
        this.settings.hideSelected = false;
    });
});