import TomSelect from 'tom-select/dist/esm/tom-select';

import { FxElement } from '@finantix/core';

import { getDom } from './common';

// This plugin replaces the clear-button plugin of the library, implementing new template and
// functionality of clear button. This one is used for the single selection.

interface CBOptions {
    className?: string;
    title?: string;
    html: (options: CBOptions) => string;
}

TomSelect.define('clear_button_single', function (this: TomSelect, options: CBOptions) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const getLocalizedTitle = (): string => {
        let title = 'Clear All';
        if (options.title) {
            title = options.title;
        } else {
            const fxElement = self.control.closest('.fx-element');
            if (fxElement instanceof FxElement) {
                title = fxElement.translateContent('fx-select.input.clear-all.label');
            }
        }

        return title;
    };

    const updateCheckboxes = (): void => {
        Object.values(self.options).forEach((option: any) => {
            const optionValue: string = option[self.settings.valueField];
            const optionHtml: HTMLElement = this.getOption(optionValue);
            if (optionHtml) {
                optionHtml.classList.toggle('selected', false);
                const checkbox = optionHtml.querySelector('input');
                if (checkbox) {
                    checkbox.checked = false;
                }
            }
        });
    };

    self.on('initialize', () => {
        const title = getLocalizedTitle();
        const className = options.className || 'mdi mdi-close';
        const button = getDom(`
            <i class="clear-button ${className}" title="${title}" aria-hidden="true"></i>
        `);
        button.addEventListener('click', (evt) => {
            if (self.isDisabled) {
                return;
            }

            updateCheckboxes();
            self.clear();

            if (self.settings.mode === 'single' && self.settings.allowEmptyOption) {
                self.addItem('');
            }

            evt.preventDefault();
            evt.stopPropagation();
        });
        self.control.appendChild(button);
    });
});
