import tippy from 'tippy.js';
import { TableLayoutModifier } from '../models';
import type { ToolbarGroup } from '../models/toolbar-group.model';
import type { ToolbarItem } from '../models/toolbar-item.model';
import { FxTableUtils } from '../utils';
import type { FxTable } from './table';

export class FxTableToolbarRenderer {
    private _component: FxTable;
    private _toolbar: HTMLElement | null;

    public constructor(component: FxTable) {
        this._component = component;
    }

    public get toolbar(): HTMLElement | null {
        return this._toolbar;
    }

    public remove(): void {
        if (this._toolbar) {
            this._toolbar.remove();
            this._toolbar = null;
        }
    }

    public render(): HTMLElement | null {
        let elementToolbar = this._toolbar;

        if (!elementToolbar) {
            elementToolbar = document.createElement('div') as HTMLDivElement;
            elementToolbar.className = 'fx-table-toolbar';
            this._toolbar = elementToolbar;
        } else {
            elementToolbar.innerHTML = '';
        }

        const appliedFormattings = [
            ...FxTableUtils.getAppliedLayoutModifiers(this._component, TableLayoutModifier.Format),
            ...FxTableUtils.getAppliedLayoutModifiers(this._component, TableLayoutModifier.Align)
        ];
        const appliedStyles: string[] = FxTableUtils.getAppliedLayoutModifiers(
            this._component,
            TableLayoutModifier.Style
        );

        let isEmpty = true;
        if (this._component.toolbarItems) {
            this._component.toolbarItems.forEach((toolbarGroup: ToolbarGroup) => {
                if (!this._isToolbarGroupEmpty(toolbarGroup)) {
                    isEmpty = false;
                    if (elementToolbar) {
                        elementToolbar.appendChild(
                            this._renderToolbarGroup(
                                toolbarGroup,
                                appliedFormattings,
                                appliedStyles
                            )
                        );
                    }
                }
            });
        }

        if (!isEmpty) {
            return elementToolbar;
        } else {
            return null;
        }
    }

    private _renderToolbarGroup(
        toolbarGroup: ToolbarGroup,
        appliedFormattings: string[],
        appliedStyles: string[]
    ): HTMLElement {
        const group = document.createElement('div') as HTMLDivElement;
        group.className = 'toolbar-group';

        toolbarGroup.items.forEach((item) => {
            if (!this._isActionLocked(this._component, item)) {
                group.appendChild(this._renderToolbarItem(item, appliedFormattings, appliedStyles));
            }
        });

        return group;
    }

    private _renderToolbarItem(
        toolbarItem: ToolbarItem,
        appliedFormattings: string[],
        appliedStyles: string[]
    ): HTMLElement {
        let item: HTMLElement;

        const disabled =
            FxTableUtils.getSelectedCells(this._component).length === 0 &&
            toolbarItem.selectionRequired === true;

        if (toolbarItem.renderer) {
            item = toolbarItem.renderer(this._component, toolbarItem);
        } else {
            item = document.createElement('button') as HTMLButtonElement;
            item.className = 'toolbar-button';
            item.title = toolbarItem.title || '';
            if (disabled) {
                item.classList.add('disabled');
            }

            if (appliedFormattings.includes(toolbarItem.action)) {
                item.classList.add('active');
            }

            if (toolbarItem.icon) {
                const icon = document.createElement('img') as HTMLImageElement;
                icon.src = toolbarItem.icon;
                item.appendChild(icon);
            } else if (toolbarItem.iconClass) {
                const icon: HTMLElement = document.createElement('i');
                icon.className = toolbarItem.iconClass;
                item.appendChild(icon);
            }

            if (toolbarItem.type === 'bg-color-picker') {
                const bgColors: string[] = toolbarItem.params;

                if (!item.classList.contains('disabled')) {
                    tippy(item, {
                        content: this._renderColorPicker(bgColors, toolbarItem.action),
                        trigger: 'click',
                        theme: 'dcc',
                        // boundary: 'window',
                        placement: 'bottom'
                    });
                }
            } else if (toolbarItem.type === 'fg-color-picker') {
                const fgColors: string[] = toolbarItem.params;

                if (!item.classList.contains('disabled')) {
                    tippy(item, {
                        content: this._renderColorPicker(fgColors, toolbarItem.action),
                        trigger: 'click',
                        theme: 'fx-table',
                        // boundary: 'window',
                        placement: 'bottom'
                    });
                }
            } else {
                item.onclick = (event: MouseEvent): void => {
                    if (!item.classList.contains('disabled')) {
                        this._component.performTableAction(toolbarItem.action, toolbarItem.params);
                    }
                };
            }
        }

        return item;
    }

    private _isToolbarGroupEmpty(group: ToolbarGroup): boolean {
        let lock = false;
        group.items.forEach((item: ToolbarItem) => {
            lock = this._isActionLocked(this._component, item);
        });

        return lock;
    }

    private _isActionLocked(component: FxTable, item: ToolbarItem): boolean {
        if (item.actionType && component.getActionsLocks(item.actionType)) {
            return true;
        } else {
            return false;
        }
    }

    private _renderColorPicker(colors: string[], action: string): HTMLElement {
        const picker = document.createElement('div') as HTMLDivElement;
        picker.className = 'fx-dcc-color-picker';

        if (colors) {
            colors.forEach((color: string) => {
                const colorItem = document.createElement('div') as HTMLDivElement;
                colorItem.className = 'color';
                colorItem.style.backgroundColor = color;

                colorItem.onclick = (event: MouseEvent): void => {
                    event.stopPropagation();

                    this._component.performTableAction(action, {
                        color: colorItem.style.backgroundColor
                    });
                };

                colorItem.onmousedown = (event: MouseEvent): void => {
                    event.stopPropagation();
                };

                picker.appendChild(colorItem);
            });
        }
        return picker;
    }
}
