export const createDom = (query: string): HTMLElement => {
    const div = document.createElement('div');
    div.innerHTML = query.trim();

    return div.firstChild as HTMLElement;
};

export const getDom = (query: HTMLElement | string): HTMLElement => {
    return query instanceof HTMLElement
        ? query
        : query.indexOf('<') > -1
        ? createDom(query)
        : document.querySelector(query);
};
