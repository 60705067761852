/* eslint-disable @typescript-eslint/no-var-requires */
export const BoldIconUrl = require('../images/format-bold.png');
export const ItalicIconUrl = require('../images/format-italic.png');
export const UnderlineIconUrl = require('../images/format-underline.png');
export const AlignLeftIconUrl = require('../images/format-align-left.png');
export const AlignRightIconUrl = require('../images/format-align-right.png');
export const AlignCenterIconUrl = require('../images/format-align-center.png');
export const AlignJustifyIconUrl = require('../images/format-align-justify.png');
export const ImageIconUrl = require('../images/file-image-outline.png');
export const TextColorIconUrl = require('../images/format-color-text.png');
export const BgColorIconUrl = require('../images/format-color-fill.png');
export const AddRowOnTopIconUrl = require('../images/table-row-plus-before.png');
export const AddRowOnBottomIconUrl = require('../images/table-row-plus-after.png');
export const RemoveRowIconUrl = require('../images/table-row-remove.png');
export const AddColumnOnLeftIconUrl = require('../images/table-column-plus-before.png');
export const AddColumnOnRightIconUrl = require('../images/table-column-plus-after.png');
export const RemoveColumnIconUrl = require('../images/table-column-remove.png');
export const MergeCellsIconUrl = require('../images/table-merge-cells.png');
export const SplitCellsIconUrl = require('../images/table-split-cell.png');
