import { TableCell, TableCellType, TableLayout, TableModel, TableRow } from '../models';
import { ToolbarItemActionType } from '../models/toolbar-item.model';
import { FxTableUtils } from '../utils';
import { FxTableDefaultCellRenderer } from './default-table-cell.renderer';
import type { FxTable } from './table';
import type { FxTableCellRenderer } from './table-cell.renderer';
import { FxTableToolbarRenderer } from './table-toolbar-renderer';
import { FxRenderer } from '@finantix/core';

export class FxTableRenderer extends FxRenderer {
    private _component: FxTable;
    private _table: HTMLTableElement;
    private _tableTitle: HTMLElement | undefined;
    private _tableFooter: HTMLElement | undefined;
    private _model: TableModel;
    private _toolbarRenderer: FxTableToolbarRenderer;
    private _defaultCellRenderer: FxTableCellRenderer;
    private _hasTableNestedRows: boolean;

    public constructor(component: FxTable) {
        super();
        this._component = component;
        this._toolbarRenderer = new FxTableToolbarRenderer(component);
        this._defaultCellRenderer = new FxTableDefaultCellRenderer();
    }

    public get table(): HTMLTableElement {
        return this._table;
    }

    public get toolbar(): HTMLElement | null {
        return this._toolbarRenderer ? this._toolbarRenderer.toolbar : null;
    }

    public get model(): TableModel {
        return this._model;
    }

    public render(_context: string, component: FxTable): void {
        this._tableTitle = undefined;
        this._tableFooter = undefined;

        this._model = component.tableModel;
        this._hasTableNestedRows = FxTableUtils.verifyIsNestedTable(this.model.rows);

        component.classList.add('fx-table');
        if (component.editable) {
            component.classList.add('editable');

            if (!this._toolbarRenderer.toolbar) {
                const tableToolbar = this._toolbarRenderer.render();
                if (tableToolbar) {
                    component.insertBefore(tableToolbar, component.firstChild);
                }
            }
        } else {
            component.classList.remove('editable');

            if (this._toolbarRenderer.toolbar) {
                this._toolbarRenderer.remove();
            }
        }

        const tableData = this._prepareTableRows();
        this._table = this._renderTable(tableData, true);
        this._tableTitle = this._renderTableTitle(component);
        this._tableFooter = this._renderTableFooter(component);

        let tableWrapper: HTMLElement | null = component.querySelector('.table');
        if (tableWrapper) {
            tableWrapper.remove();
        }

        tableWrapper = document.createElement('div');
        tableWrapper.className = 'table';

        if (
            component.doNotBreakSmallTables &&
            component.totalRows() <= FxTableUtils.DO_NOT_BREAK_SMALL_TABLES_MAX_ROWS
        ) {
            tableWrapper.classList.add('page-break-avoid');
        } else {
            tableWrapper.classList.remove('page-break-avoid');
        }

        if (this._tableTitle) {
            tableWrapper.appendChild(this._tableTitle);
        }

        const tableContainer: HTMLDivElement = document.createElement('div');
        tableContainer.className = 'table-container';
        if (this._component.allowHorizontalScrolling) {
            tableWrapper.classList.add('scrollable');
        }

        tableContainer.appendChild(this._table);

        const filePicker = tableContainer.querySelector('.file-picker');
        if (!filePicker && component.editable) {
            tableContainer.appendChild(this._renderFilePicker());
        }

        tableWrapper.appendChild(tableContainer);

        if (this._tableFooter) {
            tableWrapper.appendChild(this._tableFooter);
        }

        component.appendChild(tableWrapper);
        FxTableUtils.updateColumnsSize(this._component);
    }

    private _prepareTableRows(): TableRow {
        const data: TableRow = { cells: [], children: [] };
        data.children = this.model.rows;
        if (this._component.firstRowAsHeader) {
            data.cells = this.model.rows[0].cells;
            data.children = this.model.rows.slice(1);
        }
        return data;
    }

    private _renderTable(tableData: TableRow, isMainTable: boolean): HTMLTableElement {
        const htmlTable = document.createElement('table') as HTMLTableElement;
        htmlTable.id = FxTableUtils.uuid();
        if (!this._component.allowHorizontalScrolling) {
            htmlTable.style.width = '100%';
        }

        if (tableData.cells) {
            this._renderTHead(htmlTable, tableData);
        }

        if (tableData.children) {
            this._renderTBody(htmlTable, tableData.children);
        }

        const shouldDisplayFooter =
            this._component.lastRowAsFooter && isMainTable && this._hasTableNestedRows;
        if (shouldDisplayFooter && tableData.children) {
            const footerHeader = tableData.children[tableData.children.length - 1];
            this._renderTFoot(htmlTable, footerHeader, tableData.children.length);
        }
        return htmlTable;
    }

    private _renderTHead(table: HTMLTableElement, row: TableRow): void {
        let tableHead;
        if (!this._component.doNotRepeatHeader) {
            tableHead = table.createTHead();
        } else {
            tableHead = table;
        }
        const htmlRow = this.createTableRow(tableHead, row, 'header');
        this._renderTableRow(table, row, htmlRow, 0);
        if (htmlRow.childNodes.length === 0) {
            htmlRow.remove();
        }
    }

    public createTableRow(
        tableSection: HTMLTableSectionElement,
        row: TableRow,
        className?: string
    ): HTMLTableRowElement {
        const htmlRow: HTMLTableRowElement = document.createElement('tr');
        if (className) {
            htmlRow.classList.add(className);
        }

        if (row.rowCssClass) {
            if (typeof row.rowCssClass === 'string') {
                htmlRow.classList.add(row.rowCssClass);
            } else {
                htmlRow.classList.add(...row.rowCssClass);
            }
        }

        tableSection.appendChild(htmlRow);
        return htmlRow;
    }

    private _renderTBody(table: HTMLTableElement, rows: TableRow[]): void {
        const tableBody = table.createTBody();
        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
            const row = rows[rowIndex];
            const htmlRow = this.createTableRow(tableBody, row);
            const shouldRenderNestedTable = row.isGrouped;
            if (shouldRenderNestedTable) {
                const cell: HTMLTableCellElement = document.createElement('td');
                cell.className = 'nested';
                htmlRow.appendChild(cell);
                cell.colSpan = row.cells.length;
                cell.appendChild(this._renderTable(row, false));
            } else {
                const rowIndexToAdd: number = rowIndex + (this._component.firstRowAsHeader ? 1 : 0);
                this._renderTableRow(table, row, htmlRow, rowIndexToAdd);
            }
        }
    }

    private _renderTFoot(table: HTMLTableElement, row: TableRow, index): void {
        let tableFooter;

        if (!this._component.doNotRepeatFooter) {
            tableFooter = table.createTFoot();
        } else {
            tableFooter = table;
        }

        const htmlRow = this.createTableRow(tableFooter, row, 'footer');
        this._renderTableRow(table, row, htmlRow, index);
        if (htmlRow.childNodes.length === 0) {
            htmlRow.remove();
        }
    }

    private _renderTableRow(
        table: HTMLTableElement,
        row: TableRow,
        htmlRow: HTMLTableRowElement,
        rowIndex: number
    ): void {
        let totalTableWidth = 0;

        let rowHtml = '';

        for (let colIndex = 0; colIndex < row.cells.length; colIndex++) {
            const cell: TableCell = row.cells[colIndex];

            if (cell.hide) {
                continue;
            }

            let htmlCellTag = '';

            if (
                (rowIndex === 0 && this._component.firstRowAsHeader) ||
                (colIndex === 0 && this._component.firstColumnAsHeader) ||
                row.isTotal
            ) {
                htmlCellTag = 'th';
                rowHtml += FxTableUtils.openTag(
                    htmlCellTag,
                    row.isGrouped ? 'grouped' : 'header',
                    rowIndex === this._model.rows.length - 1 ? 'last-row' : ''
                );
            } else if (
                rowIndex === this._model.rows.length - 1 &&
                this._component.lastRowAsFooter
            ) {
                htmlCellTag = 'th';
                rowHtml += FxTableUtils.openTag(
                    htmlCellTag,
                    'footer',
                    rowIndex === this._model.rows.length - 1 ? 'last-row' : ''
                );
            } else {
                htmlCellTag = 'td';
                rowHtml += FxTableUtils.openTag(
                    htmlCellTag,
                    rowIndex === this._model.rows.length - 1 ? 'last-row' : ''
                );
            }

            rowHtml += FxTableUtils.addTagAttributes(
                {
                    key: 'data-col-idx',
                    value: `${colIndex}`
                },
                {
                    key: 'data-row-idx',
                    value: `${rowIndex}`
                }
            );

            if (cell.colSpan) {
                rowHtml += FxTableUtils.addTagAttributes({
                    key: 'colSpan',
                    value: `${cell.colSpan}`
                });
            }
            if (cell.rowSpan) {
                rowHtml += FxTableUtils.addTagAttributes({
                    key: 'rowSpan',
                    value: `${cell.rowSpan}`
                });
            }

            if (this._model.columns[colIndex].width && this._component.allowHorizontalScrolling) {
                rowHtml += FxTableUtils.addTagStyle({
                    key: 'width',
                    value: `${this._model.columns[colIndex].width}px`
                });

                totalTableWidth = totalTableWidth + this._model.columns[colIndex].width;
            }

            rowHtml += FxTableUtils.closeOpenTag();
            rowHtml += FxTableUtils.closeTag(htmlCellTag);
        }

        htmlRow.innerHTML = rowHtml;

        if (rowIndex === 0 && this._component.allowHorizontalScrolling) {
            table.style.width = `${totalTableWidth}px`;
        }

        // render row cells
        htmlRow
            .querySelectorAll('th, td')
            .forEach((htmlCell: HTMLTableCellElement, colIndex: number) => {
                htmlCell.appendChild(
                    this._renderTableCell(
                        table,
                        row.cells[colIndex],
                        rowIndex,
                        colIndex,
                        row.cells.length - 1 === colIndex
                    )
                );

                if (rowIndex === 0 && this._component.editable) {
                    const colSelectorButton: HTMLDivElement = document.createElement('div');
                    colSelectorButton.className = 'col-selector-button';
                    colSelectorButton.setAttribute('data-col-idx', '' + colIndex);

                    if (colIndex === 0) {
                        colSelectorButton.classList.add('first');
                    }
                    if (colIndex === this._model.rows[rowIndex].cells.length - 1) {
                        colSelectorButton.classList.add('last');
                    }

                    colSelectorButton.onclick = (event: MouseEvent): void => {
                        const selected = colSelectorButton.classList.contains('selected');

                        if (!event.ctrlKey && !event.metaKey) {
                            this._unselectAllCells(table);
                        }

                        if (selected) {
                            colSelectorButton.classList.remove('selected');
                        } else {
                            colSelectorButton.classList.add('selected');
                        }

                        // mark whole column selected
                        this._model.rows.forEach(
                            (tableRow: TableRow, tableRowIdx: number): void => {
                                tableRow.cells[colIndex].selected =
                                    colSelectorButton.classList.contains('selected');
                                this._renderTableCell(
                                    table,
                                    tableRow.cells[colIndex],
                                    tableRowIdx,
                                    colIndex,
                                    tableRow.cells.length - 1 === colIndex
                                );
                            }
                        );

                        this._cancelCellEditing(table);
                        this._component.performTableAction('cells-selected');
                        this._toolbarRenderer.render();
                    };

                    colSelectorButton.appendChild(
                        this._renderResizeXHandle(
                            colIndex,
                            this._model.rows[0].cells.length - 1 === colIndex,
                            this._component
                        )
                    );

                    htmlCell.appendChild(colSelectorButton);
                }

                if (colIndex === 0 && this._component.editable) {
                    const rowSelectorButton: HTMLDivElement = document.createElement('div');
                    rowSelectorButton.className = 'row-selector-button';
                    rowSelectorButton.setAttribute('data-row-idx', '' + rowIndex);
                    if (rowIndex === 0) {
                        rowSelectorButton.classList.add('first');
                    }
                    if (rowIndex === this._model.rows.length - 1) {
                        rowSelectorButton.classList.add('last');
                    }

                    rowSelectorButton.onclick = (event: MouseEvent): void => {
                        const selected = rowSelectorButton.classList.contains('selected');

                        if (!event.ctrlKey && !event.metaKey) {
                            this._unselectAllCells(table);
                        }

                        if (selected) {
                            rowSelectorButton.classList.remove('selected');
                        } else {
                            rowSelectorButton.classList.add('selected');
                        }

                        // mark whole row selected
                        this._model.rows[rowIndex].cells.forEach(
                            (cell: TableCell, tableColIndex: number) => {
                                cell.selected = rowSelectorButton.classList.contains('selected');
                                this._renderTableCell(
                                    table,
                                    cell,
                                    rowIndex,
                                    tableColIndex,
                                    this._model.rows[rowIndex].cells.length - 1 === tableColIndex
                                );
                            }
                        );

                        this._cancelCellEditing(table);
                        this._component.performTableAction('cells-selected');
                        this._toolbarRenderer.render();
                    };

                    htmlCell.appendChild(rowSelectorButton);
                }
            });
    }

    private _renderTableCell(
        table: HTMLTableElement,
        cell: TableCell,
        rowIndex: number,
        colIndex: number,
        last: boolean
    ): HTMLElement {
        let tableCell: HTMLElement | null = table.querySelector(
            `#cell-${table.id}-${rowIndex}-${colIndex}`
        );
        let cellContent: HTMLElement;

        if (!tableCell) {
            tableCell = document.createElement('div');
            tableCell.className = 'table-cell';
            tableCell.id = `cell-${table.id}-${rowIndex}-${colIndex}`;

            cellContent = document.createElement('div');
            this._setupCellStyle(tableCell, cellContent, cell);

            if (this._component.editable) {
                tableCell.onclick = (event: MouseEvent): void => {
                    if (event.ctrlKey || event.metaKey) {
                        cell.selected = !cell.selected;
                        this._renderTableCell(table, cell, rowIndex, colIndex, last);
                        this._component.performTableAction('cells-selected');
                        this._toolbarRenderer.render();
                    } else {
                        if (cell.selected) {
                            return;
                        }
                        this._unselectAllCells(table);
                        cell.selected = true;
                        this._renderTableCell(table, cell, rowIndex, colIndex, last);
                        this._component.performTableAction('cells-selected');
                        this._toolbarRenderer.render();
                    }
                };

                tableCell.ondblclick = (event: MouseEvent): void => {
                    this._unselectAllCells(table);

                    if (!this._component.getActionsLocks(ToolbarItemActionType.Content)) {
                        if (cell.type !== TableCellType.Text) {
                            cell.type = TableCellType.Text;
                            cell.content = '';
                        }
                        this._component.performTableAction('edit-cell', {
                            id: tableCell.id,
                            cell,
                            rowIndex,
                            colIndex
                        });
                        this._startCellEditing(table);
                    }
                };
            }

            tableCell.appendChild(cellContent);
        } else {
            cellContent = tableCell.querySelector('.content');
            cellContent.innerHTML = '';
            cellContent.onclick = (event: Event): void => {
                if (this._isCellEditable(tableCell)) {
                    event.stopPropagation();
                }
            };
            cellContent.oninput = (_event: Event): void => {
                cell.content = cellContent.innerText;
                if (this._component.onLayoutAdjust) {
                    this._component.onLayoutAdjust();
                }
            };
            cellContent.onblur = (_event: FocusEvent): void => {
                if (cellContent.classList.contains('editable')) {
                    cell.selected = true;
                    this._toolbarRenderer.render();
                    this._component.performTableAction('end-edit');
                }
            };
        }

        if (cell.layout && cell.layout.cellRenderer) {
            const content: string | HTMLElement = cell.layout.cellRenderer(cell);
            if (content) {
                if (typeof content === 'string') {
                    cellContent.innerHTML = content;
                } else {
                    cellContent.appendChild(content);
                }
            }
        } else {
            const renderer =
                this._component.getCustomCellRenderer(cell.type) || this._defaultCellRenderer;
            renderer.render(cell, cellContent);
        }

        if (this._isCellEditable(tableCell)) {
            cellContent.setAttribute('contenteditable', 'true');
            cellContent.focus();
        } else {
            cellContent.removeAttribute('contenteditable');
        }
        cellContent.classList.toggle('editable', this._isCellEditable(tableCell));

        if (cell.selected && this._component.editable) {
            tableCell.classList.add('selected');
        } else {
            tableCell.classList.remove('selected');
        }

        return tableCell;
    }

    private _isCellEditable(tableCell: HTMLElement): boolean {
        return (
            this._component.editedCell !== null &&
            tableCell.id === this._component.editedCell.id &&
            this._component.editable
        );
    }

    private _unselectAllCells(table: HTMLTableElement): void {
        this._model.rows.forEach((row: TableRow, rowIndex: number) => {
            row.cells.forEach((cell: TableCell, colIndex: number) => {
                if (cell.selected) {
                    cell.selected = false;
                    this._renderTableCell(
                        table,
                        cell,
                        rowIndex,
                        colIndex,
                        row.cells.length - 1 === colIndex
                    );
                }
            });
        });

        this._component.querySelectorAll('.row-selector-button').forEach((cell: Element) => {
            cell.classList.remove('selected');
        });
        this._component.querySelectorAll('.col-selector-button').forEach((cell: Element) => {
            cell.classList.remove('selected');
        });
    }

    private _startCellEditing(table: HTMLTableElement): void {
        if (this._component.editedCell) {
            const cell = this._component.editedCell.cell;
            const rowIndex = this._component.editedCell.rowIndex;
            const colIndex = this._component.editedCell.colIndex;
            this._renderTableCell(table, cell, rowIndex, colIndex, false);
        }
    }

    private _cancelCellEditing(table: HTMLTableElement): void {
        if (this._component.editedCell) {
            const cell = this._component.editedCell.cell;
            cell.selected = true;
            const rowIndex = this._component.editedCell.rowIndex;
            const colIndex = this._component.editedCell.colIndex;
            this._component.cancelCellEdit();
            this._renderTableCell(table, cell, rowIndex, colIndex, false);
        }
    }

    private _setupCellStyle(
        tableCell: HTMLElement,
        cellContent: HTMLElement,
        cell: TableCell
    ): void {
        cellContent.className = 'content';
        let tableCellCss = '';
        let contentCss = '';

        if (cell.layout) {
            const layout: TableLayout = cell.layout;
            if (layout.cellStyle) {
                let cellStyles: { [key: string]: string } = {};
                if (typeof layout.cellStyle === 'function') {
                    cellStyles = layout.cellStyle(cell);
                } else {
                    cellStyles = layout.cellStyle;
                }
                if (cellStyles) {
                    Object.keys(cellStyles).forEach((cellStyleAttribute: string) => {
                        tableCellCss += `${cellStyleAttribute}: ${cellStyles[cellStyleAttribute]}`;
                    });
                }
            }
            if (layout.align) {
                if (this._component.inlineStyles[layout.align]) {
                    contentCss += this._component.inlineStyles[layout.align] + '; ';
                } else {
                    cellContent.classList.add(layout.align);
                }
            }
            if (layout.style) {
                cellContent.classList.add(`${layout.style}`);
            }
            if (layout.formattings) {
                layout.formattings.forEach((formatting: string) => {
                    if (this._component.inlineStyles[formatting]) {
                        contentCss += this._component.inlineStyles[formatting] + '; ';
                    } else {
                        if (!cellContent.classList.contains(formatting)) {
                            cellContent.classList.add(formatting);
                        }
                    }
                });
            }
            if (layout.fgColor) {
                tableCellCss += `color: ${layout.fgColor}; `;
            }
            if (layout.bgColor) {
                tableCellCss += `background-color: ${layout.bgColor} !important; `;
            }

            tableCell.style.cssText = tableCellCss;
            cellContent.style.cssText = contentCss;
            this.addCssClasses(tableCell, layout.cssClass, cell);
        }
    }

    private _renderResizeXHandle(colIndex: number, last: boolean, component: FxTable): HTMLElement {
        const resizeXHandle: HTMLDivElement = document.createElement('div');
        last = false;
        resizeXHandle.className = last ? 'resize-x-empty' : 'resize-x-handle';

        resizeXHandle.onmousedown = (event: MouseEvent): void => {
            event.stopPropagation();
            event.preventDefault();

            const handle = event.target as HTMLElement;
            if (handle) {
                const tableContainer: HTMLElement | null = component.querySelector('.table');
                if (tableContainer) {
                    const rect: DOMRect = tableContainer.getBoundingClientRect();
                    this._createResizeIndicator(component, event.clientX, rect.top, rect.height);
                    component.startResizing(colIndex, event.clientX);
                }
            }
        };

        return resizeXHandle;
    }

    private _createResizeIndicator(
        component: FxTable,
        posX: number,
        poxY: number,
        height: number
    ): void {
        const tableContainer: HTMLElement | null = component.querySelector('.table-container');
        if (tableContainer) {
            const indicator: HTMLDivElement = document.createElement('div');
            indicator.className = 'resize-indicator';
            indicator.style.left = `${posX}px`;
            indicator.style.top = `calc(${poxY}px - 16px)`;
            indicator.style.height = `calc(${height}px + 32px)`;
            tableContainer.appendChild(indicator);
        }
    }

    private _renderTableTitle(component: FxTable): HTMLElement | undefined {
        if (!component.tableTitle) {
            return undefined;
        }

        const title: HTMLElement = document.createElement('h3');
        title.className = 'table-title';
        if (component.tableTitle.content) {
            title.innerText = component.tableTitle.content || '';
        }
        if (component.tableTitle.layout) {
            if (component.tableTitle.layout.align) {
                title.style.textAlign = component.tableTitle.layout.align;
            }
            this.addCssClasses(title, component.tableTitle.layout.cssClass);
        }
        return title;
    }

    private addCssClasses(
        htmlElement: HTMLElement,
        cssClass: string | string[] | ((cell: TableCell) => string | string[]),
        cell?: TableCell
    ): void {
        if (!cssClass) {
            return;
        }

        let classes: string | string[];

        if (typeof cssClass === 'function') {
            classes = cssClass(cell);
        } else {
            classes = cssClass;
        }

        if (typeof classes === 'string') {
            htmlElement.classList.add(classes);
        } else {
            htmlElement.classList.add(...classes);
        }
    }

    private _renderTableFooter(component: FxTable): HTMLElement | undefined {
        if (!component.tableComment) {
            return undefined;
        }
        const footer: HTMLElement = document.createElement('h6');
        footer.className = 'table-footer';
        if (component.tableComment && component.tableComment.content) {
            footer.innerText = component.tableComment.content || '';
        }
        return footer;
    }

    private _renderFilePicker(): HTMLElement {
        const filePicker: HTMLInputElement = document.createElement('input');
        filePicker.className = 'file-picker';
        filePicker.style.width = '1px';
        filePicker.type = 'file';
        filePicker.style.display = 'none';

        filePicker.onclick = (event: MouseEvent): void => {
            event.stopPropagation();
        };

        filePicker.onchange = (event: Event): void => {
            const target: any = event.target;
            const files = target.files;

            const reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = (_event: Event): void => {
                this._component.performTableAction('image-picked', {
                    content: {
                        file: files[0],
                        content: reader.result,
                        cellImage: {
                            filename: files[0].name,
                            size: files[0].size,
                            mimeType: files[0].type
                        }
                    }
                });
                (filePicker as any).value = null;
            };
        };
        return filePicker;
    }
}
