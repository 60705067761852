import { FxTableDatasource, DatasourceConfiguration } from '../core';
import { TableRow, TableCell, TableColumn, TableCellType } from '../models';

export class FxTableDefaultDatasource extends FxTableDatasource {
    private _data: TableRow[];

    public constructor(configuration: DatasourceConfiguration, rows: any[]) {
        super(configuration);
        this._prepareData(rows);
    }

    public getData(params?: any): TableRow[] {
        return this._data;
    }

    private _prepareData(rows: any[]): void {
        this._data = [];

        rows.forEach((row: any) => {
            const cells: TableCell[] = [];

            this.configuration.columns.forEach((column: TableColumn) => {
                const cell: TableCell = {
                    type: TableCellType.Text,
                    content: column.property ? row[column.property] : '',
                    layout: {}
                };
                cells.push(cell);
            });

            rows.push({
                cells
            });
        });
    }
}
