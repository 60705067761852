import TomSelect from 'tom-select/dist/esm/tom-select';
import { FxSelectClearEvent } from '../fx-select.events';

//This plugin replaces the clear-button plugin of the library, implementing new template and functionality of clear button. This one is used for the multiple selection.

TomSelect.define('clear_button_multiple', function (this: TomSelect) {
    const getDom = query => {
        if (query.jquery) {
            return query[0];
        }

        if (query instanceof HTMLElement) {
            return query;
        }

        if (query.indexOf('<') > -1) {
            let div = document.createElement('div');
            div.innerHTML = query.trim();

            return div.firstChild;
        }

        return document.querySelector(query);
    };

    const self = this;
    const options = Object.assign({
        html: () => {
            return `<i class="mdi mdi-close clear-button-multi" aria-hidden="true"></i>`;
        }
    });

    self.on('initialize', () => {
        var button = getDom(options.html(options));
        button.addEventListener('click', evt => {
            this.input.parentElement.dispatchEvent(new FxSelectClearEvent({
                select: this.input.parentElement
            }));
            self.clear();
            evt.preventDefault();
            evt.stopPropagation();
            this.clearActiveItems();
        });
        setTimeout(() => {
            let dropdownIcon: HTMLElement = self.control.querySelector('.dropdown-arrow');
            self.control.insertBefore(button, dropdownIcon);
            if (this.items.length === 0) {
                let clearButton = this.control.querySelector('.clear-button-multi');
                if (clearButton)
                    clearButton.style.visibility = 'hidden';
            }
        });
    });

    this.hook('after', 'refreshOptions', () => {
        if (this.items.length === 0)
            this.clearActiveOption();
    });

    this.hook('after', 'onOptionSelect', () => {
        if (this.plugins.names.includes('clear_button_multiple')) {
            if (this.items.length != 0) {
                let clearButton: HTMLElement = this.control.querySelector('.clear-button-multi');
                if (clearButton)
                    clearButton.style.visibility = 'visible';
            }
        }
    });

    this.hook('after', 'clear', () => {
        let itemSelectedSpan: HTMLElement = this.control.querySelector('.multi-select-text');
        if (itemSelectedSpan) {
            itemSelectedSpan.innerText = '';
        }
        if (this.items.length === 0) {
            let clearButton: HTMLElement = this.control.querySelector('.clear-button-multi');
            if (clearButton)
                clearButton.style.visibility = 'hidden';
            let placeholder: HTMLElement = this.control.querySelector('.placeholder');
            if (placeholder) {
                placeholder.style.display = 'block';
            }
        }
        if (this.dropdown_content.children.length > 0) {
            let children = this.dropdown_content.children as Array<HTMLElement>;
            Array.from(children).forEach(child => {
                var checkbox = child.querySelectorAll('input');
                checkbox.forEach(element => {
                    if (element) {
                        if (element.classList.contains('selected')) {
                            element.checked = true;
                        } else {
                            element.checked = false;
                        }
                    }
                });
            });
        }
        this.clearActiveOption();
    });

    this.hook('after', 'onKeyDown', (e: KeyboardEvent) => {
        this.input.parentElement.dispatchEvent(new FxSelectClearEvent({
            select: this.input.parentElement
        }));
        if (e.code === 'Backspace') {
            if (!this.isOpen) {
                this.clear();
                this.deleteSelection();
                this.control.querySelector('.multi-select-text').innerHTML = '';
            }
        }
    })
});