import tippy, { Instance } from 'tippy.js';
import { FxRenderer } from '@finantix/core';
import type { FxDropdown } from './fx-dropdown';
import { FxDropdownCloseEvent, FxDropdownOpenEvent } from './fx-dropdown.events';

export class FxDropdownRenderer extends FxRenderer {
    public constructor() {
        super();
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public render(context: string, menu: FxDropdown) {
        if (menu.triggerElement && !menu.triggerElement['_tippy']) {
            const tippyInstance = tippy(menu.triggerElement, {
                content: menu,
                offset: [0, 8],
                interactive: true,
                appendTo: menu.appendTo ? menu.appendTo : 'parent',
                trigger: 'click',
                placement: 'bottom-start',
                arrow: false,
                duration: [0, 0],
                role: 'combobox',
                popperOptions: {
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                flipVariations: false
                            }
                        }
                    ]
                },
                onShow: (_instance: Instance) => {
                    menu.dispatchEvent(new FxDropdownOpenEvent({ dropdown: menu }));
                },
                onHide: (_instance: Instance) => {
                    menu.dispatchEvent(new FxDropdownCloseEvent({ dropdown: menu }));
                }
            });

            // Add more CSS classes to the root of the tippy instance
            tippyInstance.popper.classList.toggle('flexible-height', menu.variableHeight);
            menu.classes.forEach((cssClass) => {
                if (cssClass.length > 0) {
                    tippyInstance.popper.classList.toggle(cssClass, true);
                }
            });

            this.onMenuItemsClick(menu, tippyInstance);
            this.onMenuItemsUpdate(menu, tippyInstance);
            this.onMenuKeypressEsc(menu, menu.triggerElement, tippyInstance);
            this.onMenuTriggerKeypressUpDown(menu.triggerElement, menu, tippyInstance);
            this.onMenuTriggerKeypressEsc(menu.triggerElement, tippyInstance);
            this.onMenuKeypressUpDown(menu);
        }
    }

    private closeMenu(tippyInstance: Instance, trigger?: HTMLElement): void {
        if (tippyInstance.state.isVisible) {
            tippyInstance.hide();
            if (trigger) {
                trigger.focus();
            }
        }
    }

    private onMenuItemsClick(menu: FxDropdown, tippyInstance: Instance): void {
        menu.querySelectorAll('.dropdown-item:not([data-keep-opened-on-click])').forEach(
            (menuItem: HTMLElement) => {
                // It's still under evaluation if we want to focus back the trigger once the
                // dropdown is closed due to a click on an item. To activate the behaviour just
                // pass the trigger to the closeMenu method
                menuItem.addEventListener('click', () => this.closeMenu(tippyInstance));
            }
        );
    }

    private onMenuItemsUpdate(menu: FxDropdown, tippyInstance: Instance): void {
        const config = { childList: true };

        const callback = (mutationsList: MutationRecord[]): void => {
            mutationsList.forEach((mutation: MutationRecord) => {
                mutation.addedNodes.forEach((addedNode: HTMLElement) => {
                    if (addedNode.className === 'dropdown-item') {
                        addedNode.addEventListener('click', () => this.closeMenu(tippyInstance));
                    }
                });
            });
        };

        const observer = new MutationObserver(callback);
        observer.observe(menu, config);
    }

    private onMenuKeypressEsc(
        menu: FxDropdown,
        trigger: HTMLElement,
        tippyInstance: Instance
    ): void {
        menu.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.keyCode === 27) {
                this.closeMenu(tippyInstance, trigger);
            }
        });
    }

    private onMenuTriggerKeypressUpDown(
        trigger: HTMLElement,
        menu: FxDropdown,
        tippyInstance: Instance
    ): void {
        trigger.addEventListener('keydown', (event: KeyboardEvent) => {
            if ([38, 40].includes(event.keyCode)) {
                event.preventDefault();
                if (tippyInstance.popperInstance) {
                    this.focusFirstMenuItem(menu, tippyInstance.popperInstance.state.placement);
                } else {
                    tippyInstance.show();
                }
            }
        });
    }

    private onMenuTriggerKeypressEsc(trigger: HTMLElement, tippyInstance: Instance): void {
        trigger.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.keyCode === 27) {
                this.closeMenu(tippyInstance, trigger);
            }
        });
    }

    private onMenuKeypressUpDown(menu: FxDropdown): void {
        menu.addEventListener('keydown', (event: KeyboardEvent) => {
            if ([38, 40].includes(event.keyCode)) {
                const focusableList = this.seekCurrentFocusedElement(menu);
                event.preventDefault();
                event.stopImmediatePropagation();
                switch (event.keyCode) {
                    case 38:
                        focusableList.previousNode();
                        break;
                    case 40:
                        focusableList.nextNode();
                        break;
                }

                if (focusableList.currentNode instanceof HTMLElement) {
                    focusableList.currentNode.focus();
                }
            }
        });
    }

    private getFocusableNodeListIn(root: HTMLElement, moveToFirst: boolean = true): TreeWalker {
        const tw: TreeWalker = document.createTreeWalker(root, NodeFilter.SHOW_ELEMENT, {
            acceptNode: (node: HTMLElement) =>
                this.isNodeFocusable(node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP
        });

        if (moveToFirst) {
            tw.nextNode();
        }
        return tw;
    }

    private isNodeFocusable(node: HTMLElement): boolean {
        const isEnabled =
            !node.hasAttribute('disabled') || node.getAttribute('disabled') === 'false';
        return node.tabIndex >= 0 && isEnabled;
    }

    private focusFirstMenuItem(menu: FxDropdown, placement: string): void {
        const focusableList = this.getFocusableNodeListIn(menu, true);
        let menuItemToFocus: Node | null = focusableList.currentNode;
        const isUpDirection: boolean = placement.startsWith('top');
        if (isUpDirection) {
            while (focusableList.nextNode()) {
                menuItemToFocus = focusableList.currentNode;
            }
        }

        if (menuItemToFocus instanceof HTMLElement) {
            menuItemToFocus.focus();
        }
    }

    private seekCurrentFocusedElement(menu: HTMLElement): TreeWalker {
        const focusableList = this.getFocusableNodeListIn(menu, false);
        if (document.activeElement instanceof HTMLElement) {
            let currentNode = focusableList.nextNode();
            while (currentNode !== document.activeElement) {
                currentNode = focusableList.nextNode();
            }
        }

        return focusableList;
    }
}
