import type { TableRow, TableColumn } from '../models';

export enum DatasourceType {
    Static = 'static'
}

export enum DatasourceSortOrder {
    Asc = 'asc',
    Desc = 'desc'
}

export interface DatasourceSort {
    property: string;
    order: DatasourceSortOrder;
}

export interface DatasourceConfiguration {
    type: DatasourceType;
    columns: TableColumn[];
    sort?: DatasourceSort[];
}

export abstract class FxTableDatasource {
    private _configuration: DatasourceConfiguration;

    public constructor(configuration: DatasourceConfiguration) {
        this._configuration = configuration;
    }

    public get configuration(): DatasourceConfiguration {
        return this._configuration;
    }

    public abstract getData(params?: any): TableRow[];
}
